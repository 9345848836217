import Title from "../components/format/Title";
import './pages.css';
import {useParams, Link} from "react-router-dom";
import axios from "axios";
import Libs from "../Libs";
import {memo, useEffect, useState} from "react";

const Confirmation = () => {

    const [message, setMessage] = useState('');
    const [button, setButton] = useState(<Link to={''}/>);
    const {id, key} = useParams();

    // On éxécute seulement au chargement du composant
    useEffect(() => {
        // On demande au backend de vérifier sur le couple ID et KEY est bon
        axios.post('/back/confirmation', {
            id: id,
            key: key
        })
        .then(function (response) {
            // On vérifie si le backend nous retourne un message d'erreur ou non
            if(response.data.status) {
                setMessage(Libs.textValide('Adresse mail confirmée !'));
                setButton(<Link className="linkLoRI" to={'/login'} >Connexion</Link>);
            }
            else {
                setMessage(Libs.textError(response.data.message));
            }
        })
        .catch(function (error) {
            axios.post('/back/error', {
                code: 2000,
                value: error
            }).then(function (response) {});
        });
        // eslint-disable-next-line
    }, [])

    return (
        <div className='page minWidth'>
            <Title>Confirmation</Title>
            {message}
            {button}
        </div>
    )
}

export default memo(Confirmation);