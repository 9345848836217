import Title from "../components/format/Title";
import './pages.css';
import FormAccount from "../components/forms/FormAccount";
import FormNewPassword from "../components/forms/FormNewPassword";
import {Link} from "react-router-dom";

const Account = ({user, setUser}) => {

    return (
        <div className='page'>
            <Title>Mon Compte</Title>
            <FormAccount user={user} setUser={setUser} />
            <br/>
            <br/>
            <fieldset>
                <FormNewPassword />
            </fieldset>
            <br/>
            <Link to={'/account/delete'} ><button className="deleteColor">Supprimer mon Compte</button></Link>
        </div>
    );
}

export default Account;