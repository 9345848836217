import Forms from "../format/Forms";
import {memo, useEffect, useState} from "react";
import axios from "axios";
import Libs from "../../Libs";

const FormSettings = ({setShowLog}) => {

    const [boxLogHistory, setBoxLogHistory] = useState(false);
    const [nbViewTrack, setNbViewTrack] = useState({values: [10,20,30,40,50,100,150,200], choice: 10});
    const [change, setChange] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        // On récupère la liste des groupes
        axios.post('/back/settings-user/get')
            .then(function (response) {
                // On vérifie si le backend nous retourne un message d'erreur ou non
                if(response.data.status) {
                    setBoxLogHistory(response.data.values[0].settings_users_connections_log === 1);
                    setNbViewTrack({values: nbViewTrack.values, choice: response.data.values[0].settings_users_number_view_trackings});
                    setShowLog(response.data.values[0].settings_users_connections_log === 1);
                }
                else {
                    if(response.data.connected) {
                        setMessage(Libs.textError('Une erreur est survenue'));
                    }
                    else {
                        window.location.reload();
                    }
                }
            })
            .catch(function (error) {
                axios.post('/back/error', {
                    code: 2000,
                    value: error
                }).then(function (response) {});
            });
        // eslint-disable-next-line
    }, []);


    // Fonction de mise à jour en BDD
    useEffect(() => {
        if(change) {
            axios.post('/back/settings-user/edit', {
                logHistory: boxLogHistory,
                track_show: nbViewTrack.choice,
                list: nbViewTrack.values
            })
                .then(function (response) {
                    // On vérifie si le backend nous retourne un message d'erreur ou non
                    if(response.data.status) {
                        setMessage(Libs.textValide(response.data.message));
                    }
                    else {
                        if(response.data.connected) {
                            setMessage(Libs.textError('Une erreur est survenue'));
                        }
                        else {
                            window.location.reload();
                        }
                    }

                    setChange(false);
                })
                .catch(function (error) {
                    axios.post('/back/error', {
                        code: 2000,
                        value: error
                    }).then(function (response) {});
                });
        }
        // eslint-disable-next-line
    }, [change]);


    // Fonction qui actualise le state quand on modifie la valeur des input
    const changeField = (e, submit) => {

        setMessage('');

        // Action en fonction du champ modifié
        switch (e.target.name) {
            case 'boxLogHistory':
                setBoxLogHistory(e.target.checked);
                setShowLog(e.target.checked);
                break;

            case 'nb_show':
                if(nbViewTrack.values.includes(parseInt(e.target.value))) {
                    setNbViewTrack({values: nbViewTrack.values, choice: e.target.value});
                    setMessage('');
                }
                else {
                    setMessage(Libs.textError('Valeur incorrect !'));
                }
                break;

            default:
                break;
        }

        setChange(true);
    }

    return (
        <div className="center">
            {message}
            <form className="maxWidth">
                <Forms type="checkbox" value="boxLogHistory" name="boxLogHistory" checked={boxLogHistory} func={(e) => changeField(e, false)}> Activer historique de connexion</Forms>
                <br/>
                <Forms type='selectListNumber' value={nbViewTrack.values} name="nb_show" func={(e) => changeField(e, false)} selected={nbViewTrack.choice} >Nombre de trackings affichés par défaut: </Forms>
            </form>
        </div>
    );
}

export default memo(FormSettings);