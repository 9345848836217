import {memo, useEffect, useState} from "react";
import Title from "../../components/format/Title";
import axios from "axios";
import {Link} from "react-router-dom";
import icon_delete from "../../assets/icons/delete.png";

const AdminRequests = () => {

    const [requests, setRequests] = useState([]);

    useEffect(() => {
        axios.post('/back/requets/get')
            .then(function (response) {
                // On vérifie si le backend nous retourne un message d'erreur ou non
                if(response.data.status) {
                    setRequests(response.data.values);
                }
                else {
                    if(response.data.connected) {
                    }
                    else {
                        window.location.reload();
                    }
                }
            })
            .catch(function (error) {
                axios.post('/back/error', {
                    code: 2000,
                    value: error
                }).then(function (response) {});
            });
    }, []);


    let list;
    let color = false;

    if(requests.length > 0) {
        list = (
            <table>
                <thead>
                    <tr>
                        <th>
                            Référence
                        </th>
                        <th>
                            URL
                        </th>
                        <th>
                            Utilisateur
                        </th>
                    </tr>
                </thead>
                <tbody>
                {
                    requests.map((value, index) => {

                        color = color ? false : true;

                        return (
                            <tr key={index} className={color ? 'backTr' : ''}>
                                <td className="tdGroups">
                                    {value.add_request_reference}
                                </td>
                                <td className="tdGroups">
                                    <a href={value.add_request_url} target="_blank" rel="noreferrer">{value.add_request_url}</a>
                                </td>
                                <td className="tdGroups">
                                    {value.users_first_name} {value.users_second_name}
                                </td>
                                <td className="leftBar tdGroups tdMin center">
                                    <Link to={`/admin/requests/delete/${encodeURI(value.add_request_id)}`} ><img className="icon" alt="delete" src={icon_delete}/></Link>
                                </td>
                            </tr>
                        );
                    })
                }
                </tbody>
            </table>
        );
    }
    else {
        list = "Aucune demande";
    }


    return (
        <div className='page'>
            <Title>Administration Demandes Livreur</Title>
            <div>
                {list}
            </div>
        </div>
    );
}

export default memo(AdminRequests)