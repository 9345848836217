import {memo, useState} from "react";
import Forms from "../format/Forms";
import {Link} from "react-router-dom";
import axios from "axios";
import Libs from "../../Libs";

const FormLogin = (props) => {

    const {setUser, setConnected} = props.data;

    const [email, setEmail] = useState({text: '', check: false, error: ''});
    const [password, setPassword] = useState({text: '', check: false, error: ''});
    const [saveConnexion, setSaveConnexion] = useState({text: false, check: false, error: ''});
    const [message, setMessage] = useState('');
    // eslint-disable-next-line
    const [linkConfirm, setLinkConfirm] = useState(<a href="#"></a>);

    // Fonction qui actualise le state quand on modifie la valeur des input
    const changeField = (e, submit) => {

        let result;
        let value;

        // Action en fonction du champ modifié
        switch (e.target.name) {
            case 'email':
                value = submit ? email.text : e.target.value
                result = Libs.checkField('noempty', value);

                setEmail({
                    text: value,
                    check: result.check,
                    error: result.error})
                break;

            case 'password':
                value = submit ? password.text : e.target.value
                result = Libs.checkField('noempty', value);

                setPassword({
                    text: value,
                    check: result.check,
                    error: result.error})
                break;

            case 'saveConnexion':
                value = submit ? saveConnexion.text : e.target.checked

                setSaveConnexion({
                    text: value,
                    check: true,
                    error: ''})
                break;

            default:
                break;
        }
    }

    // Si on clic sur le lien de renvoi du mail de confirmation
    const resendMailConfirmation = () => {
        axios.post('/back/resendmail', {
            email: email.text
        })
            .then(function (response) {
                // On vérifie si le backend nous retourne un message d'erreur ou non
                if(response.data.status) {
                    setMessage(Libs.textValide(response.data.message));
                }
                else {
                    setMessage(Libs.textError(response.data.message));
                }
            })
            .catch(function (error) {
                axios.post('/back/error', {
                    code: 2000,
                    value: error
                }).then(function (response) {});
            });
    }

    // Fonction qui s'exécute quand on clique sur le bouton valider
    const sumbitForm = (e) => {
        e.preventDefault();

        changeField({target: {name: 'email'}}, true);
        changeField({target: {name: 'password'}}, true);
        changeField({target: {name: 'saveConnexion'}}, true);

        if(email.check && password.check) {

            axios.post('/back/login', {
                email: email.text,
                password: password.text,
                saveConnexion: saveConnexion.text
            })
                .then(function (response) {
                    // On vérifie si le backend nous retourne un message d'erreur ou non
                    if(response.data.status) {
                        setMessage(Libs.textValide(response.data.message));
                        setUser(response.data.user);
                        setConnected(true);
                    }
                    else {
                        setMessage(Libs.textError(response.data.message));
                        if(response.data.confirmation) {
                            // eslint-disable-next-line
                            setLinkConfirm(<a href="#" onClick={resendMailConfirmation}>Renvoyer le mail de confirmation</a>)
                        }
                    }
                })
                .catch(function (error) {
                    axios.post('/back/error', {
                        code: 2000,
                        value: error
                    }).then(function (response) {});
                });
        }
    }

    return (
        <div className="pageContent">
            <div className="center">{message}<br/>{linkConfirm}</div>
            <form className='form' onSubmit={sumbitForm}>
                <Forms type='email' value={email.text} name="email" obligatory="true" func={(e) => changeField(e, false)}>Mail</Forms>
                {email.error}
                <br/>
                <Forms type='password' value={password.text} name="password" obligatory="true" func={(e) => changeField(e, false)}>Mot de Passe</Forms>
                {password.error}
                <Forms type="checkbox" value={saveConnexion.text} name="saveConnexion" func={(e) => changeField(e, false)}>Rester connecté</Forms>
                {saveConnexion.error}
                <br/>
                <br/>
                <div className="center">
                    <Forms type='submit'>Valider</Forms>
                    <Link className="linkLoRI" to={'/register'} >Inscription</Link>
                </div>
            </form>
            <br/>
            <div className="center">
                <Link className="reduceSize" to={'/reset-password'}>Mot de passe oublié</Link>
            </div>
        </div>
    )
}

export default memo(FormLogin);