import {Link} from "react-router-dom";
import icon_new from '../../assets/icons/new.png';
import icon_transit from '../../assets/icons/transit.png';
import icon_delivered from '../../assets/icons/delivered.png';
import icon_get_back from '../../assets/icons/get-back.png';
import icon_undelivered from '../../assets/icons/undelivered.png';
import icon_alert from '../../assets/icons/alert.png';
import icon_unknown from '../../assets/icons/unknown.png';
import icon_delete from '../../assets/icons/delete.png';

const TrackingBloc = (props) => {

    const {trackings_id, trackings_reference, trackings_name, status_name, status_icon} = props.data;

    let img;

    switch(status_icon) {
        case 'new':
            img = icon_new;
            break;
        case 'transit':
            img = icon_transit;
            break;
        case 'delivered':
            img = icon_delivered;
            break;
        case 'get-back':
            img = icon_get_back;
            break;
        case 'undelivered':
            img = icon_undelivered;
            break;
        case 'alert':
            img = icon_alert;
            break;
        case 'unknown':
            img = icon_unknown;
            break;
        default:
            break;
    }

    return (
        <div className='trackingBloc'>
            <h2><img className="icon" alt={status_icon} src={img} /> <Link to={`/tracking/details/${trackings_id}`} >{trackings_name}</Link></h2>
            <br/>
            <h3>Référence:</h3> {trackings_reference}
            <br/>
            <h3>Status:</h3> {status_name}
            <br/>
            <div className='delete'>
                <Link to={`/tracking/delete/${trackings_id}`} ><img className="icon" alt='delete' src={icon_delete}/></Link>
            </div>
        </div>
    )
}

export default TrackingBloc;