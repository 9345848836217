class Libs {
    // Affichage du text en erreur
    static textError(text){
        return <span className="error">{text}</span>;
    }

    // Affichage du text valide
    static textValide(text){
        return <span className="valide">{text}</span>;
    }

    // Vérification des valeurs rentré dans les inputs
    static checkField(type, value) {
        let errorValue = '';
        let checkValue = true;

        switch(type) {
            case 'name':
                if(value !== undefined) {
                    if(value.length <= 30) {
                        if(value.length >= 1) {
                            if(value.match(/^ /i) == null) {
                                if(value.match(/^[a-zàáâæçèéêëìíîïñòóôœùúûüýÿ]+ ?([a-zàáâæçèéêëìíîïñòóôœùúûüýÿ]+)? ?([a-zàáâæçèéêëìíîïñòóôœùúûüýÿ]+)?$/i) == null) {
                                    errorValue = 'Ne peut contenir que des lettres et espaces';
                                    checkValue = false;
                                }
                            }
                            else {
                                errorValue = 'Ne doit pas commencer par un espace';
                                checkValue = false;
                            }
                        }
                        else {
                            errorValue = 'Ne doit pas être vide';
                            checkValue = false;
                        }
                    }
                    else {
                        errorValue = 'Ne doit pas dépasser 30 caractères';
                        checkValue = false;
                    }
                }
                else {
                    errorValue = 'Ne doit pas être vide';
                    checkValue = false;
                }
                break;

            case 'email':
                if(value !== undefined) {
                    if(value.length <= 80) {
                        if(value.length >= 1) {
                            if(value.match(/^ /i) == null) {
                                // eslint-disable-next-line
                                if(value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i) == null) {
                                    errorValue = 'Format incorrect';
                                    checkValue = false;
                                }
                            }
                            else {
                                errorValue = 'Ne doit pas commencer par un espace';
                                checkValue = false;
                            }
                        }
                        else {
                            errorValue = 'Ne doit pas être vide';
                            checkValue = false;
                        }
                    }
                    else {
                        errorValue = 'Ne doit pas dépasser 80 caractères';
                        checkValue = false;
                    }
                }
                else {
                        errorValue = 'Ne doit pas être vide';
                        checkValue = false;
                }
                break;

            case 'password':
                if(value !== undefined) {
                    if (value.length <= 255) {
                        if (value.length < 1) {
                            errorValue = 'Ne doit pas être vide';
                            checkValue = false;
                        }
                    } else {
                        errorValue = 'Ne doit pas dépasser 255 caractères';
                        checkValue = false;
                    }
                }
                else {
                    errorValue = 'Ne doit pas être vide';
                    checkValue = false;
                }
                break;

            case 'passwordC':
                if(value[0] !== undefined) {
                    if (value[0].length <= 255) {
                        if (value[0].length >= 1) {
                            if(value[0] !== value[1]) {
                                errorValue = 'Les mots de passe ne correspondent pas';
                                checkValue = false;
                            }
                        }
                        else {
                            errorValue = 'Ne doit pas être vide';
                            checkValue = false;
                        }
                    } else {
                        errorValue = 'Ne doit pas dépasser 255 caractères';
                        checkValue = false;
                    }
                }
                else {
                    errorValue = 'Ne doit pas être vide';
                    checkValue = false;
                }
                break;

            case 'phone':
                if(value[0] !== undefined) {
                    if (value.length <= 10) {
                        if (value.match(/^(0[6-7][0-9]{8})$/) == null) {
                            errorValue = 'Format incorrect';
                            checkValue = false;
                        }
                    } else {
                        errorValue = 'Ne doit pas dépasser 10 chiffres';
                        checkValue = false;
                    }
                }
                break;

            case 'checkbox':
                if(!value) {
                    errorValue = 'Les CGU doivent être accepter';
                    checkValue = false;
                }
                break;

            case 'noempty':
                if(value[0] === undefined) {
                    errorValue = 'Ne doit pas être vide';
                    checkValue = false;
                }
                break;

            case 'nbCharac':
                if(value[2].length < value[0]) {
                    errorValue = `Minimum ${value[0]} caractères`;
                    checkValue = false;
                }
                else if (value[2].length > value[1]) {
                    errorValue = `Ne doit pas dépasser ${value[1]} caractères`;
                    checkValue = false;
                }
                break;

            case 'selectCompanies':
                if(parseInt(value) === 0) {
                    errorValue = 'Choisissez une valeur';
                    checkValue = false;
                }
                break;

            case 'selecsGroups':
                if(isNaN(parseInt(value))) {
                    errorValue = 'Choisissez une valeur';
                    checkValue = false;
                }
                break;

            default:
                break;
        }

        return {
            error: Libs.textError(errorValue),
            check: checkValue
        }
    }

    static showDate(date) {
        let result;

        if (date != null)
        {
            if (date.match(/^([0-9]{4})-([0-9]{2})-([0-9]{2})T([0-9]{2}):([0-9]{2}):([0-9]{2}).+$/i)) {

                const value = date.match(/^([0-9]{4})-([0-9]{2})-([0-9]{2})T([0-9]{2}):([0-9]{2}):([0-9]{2}).+$/i);

                const year = value[1];
                const month = value[2];
                const day = value[3];

                const hour = value[4];
                const minute = value[5];

                result = `${day}/${month}/${year} à ${hour}h${minute}`;
            }
        }
        else {
            result = 'NC';
        }

        return result;
    }
}

export default Libs;