import {memo, useState} from "react";
import Forms from "../format/Forms";
import Libs from "../../Libs";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";

const FormTrackingEdit = (props) => {

    const {trackings_id, trackings_reference, trackings_name, trackings_groups_id, trackings_comment} = props.tracking[0];

    const [name, setName] = useState({text: trackings_name, check: true, error: ''});
    const [groups, setGroups] = useState({values: props.groups, choice: trackings_groups_id === null ? 0: trackings_groups_id, check: true, error: ''});
    const [comment, setComment] = useState({text: trackings_comment === null ? '' : trackings_comment, check: true, error: ''});
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    // Fonction qui actualise le state quand on modifie la valeur des input
    const changeField = (e, submit) => {

        let result;
        let value;

        // Action en fonction du champ modifié
        switch (e.target.name) {
            case 'name':
                value = submit ? name.text : e.target.value
                result = Libs.checkField('nbCharac', [0, 45, value]);

                setName({
                    text: value,
                    check: result.check,
                    error: result.error})
                break;

            case 'groups':
                value = submit ? groups.choice : e.target.value
                result = Libs.checkField('selectGroups', value);

                setGroups({
                    values: groups.values,
                    choice: value,
                    check: result.check,
                    error: result.error})
                break;

            case 'comment':
                value = submit ? comment.text : e.target.value
                result = Libs.checkField('nbCharac', [0, 65535, value]);

                setComment({
                    text: value,
                    check: result.check,
                    error: result.error})
                break;

            default:
                break;
        }
    }

    // Fonction qui s'exécute quand on clique sur le bouton valider
    const sumbitForm = (e) => {
        e.preventDefault();

        changeField({target: {name: 'name'}}, true);
        changeField({target: {name: 'groups'}}, true);
        changeField({target: {name: 'comment'}}, true);

        // On vérifie que tous les champs du formulaire sont ok
        if(name.check && comment.check && groups.check) {
            axios.post('/back/tracking/edit', {
                trackings_id: trackings_id,
                reference: trackings_reference,
                name: name.text,
                groups: parseInt(groups.choice),
                comment: comment.text
            })
                .then(function (response) {
                    // On vérifie si le backend nous retourne un message d'erreur ou non
                    if(response.data.status) {
                        navigate(-1);
                    }
                    else {
                        if(response.data.connected) {
                            setMessage(Libs.textError(response.data.message));
                        }
                        else {
                            window.location.reload();
                        }
                    }
                })
                .catch(function (error) {
                    axios.post('/back/error', {
                        code: 2000,
                        value: error
                    }).then(function (response) {});
                });
        }
    }

    return (
        <div className="pageContent">
            <div className="center">{message}</div>
            <h3>Référence: </h3>{trackings_reference}
            <br/>
            <br/>
            <form className='form' onSubmit={sumbitForm}>
                <Forms type='text' value={name.text} name="name" func={(e) => changeField(e, false)}>Nom</Forms>
                {name.error}
                <br/>
                <Forms type='selectGroups' value={groups.values} name="groups" selected={groups.choice === null ? 0 : groups.choice} func={(e) => changeField(e, false)} >Aucun</Forms>
                {groups.error}
                <br/>
                <Forms type='textarea' value={comment.text} name="comment" func={(e) => changeField(e, false)}>Commentaire</Forms>
                {comment.error}
                <br/>
                <Forms type='submit'>Modifier</Forms><Link to={`/tracking/details/${trackings_id}`}>Annuler</Link>
            </form>
        </div>
    )
}

export default memo(FormTrackingEdit);