import Forms from "../format/Forms";
import {useState} from "react";
import axios from "axios";
import Libs from "../../Libs";

const FormContact = () => {

    const [subject, setSubject] = useState({text: '', check: false, error: ''});
    const [text, setText] = useState({text: '', check: false, error: ''});
    const [message, setMessage] = useState('');


    // Fonction qui actualise le state quand on modifie la valeur des input
    const changeField = (e, submit) => {

        let result;
        let value;

        // Action en fonction du champ modifié
        switch (e.target.name) {
            case 'subject':
                value = submit ? subject.text : e.target.value
                result = Libs.checkField('nbCharac', [10, 60, value]);

                setSubject({
                    text: value,
                    check: result.check,
                    error: result.error})
                break;

            case 'comment':
                value = submit ? text.text : e.target.value
                result = Libs.checkField('nbCharac', [35, 65535, value]);

                setText({
                    text: value,
                    check: result.check,
                    error: result.error})
                break;

            default:
                break;
        }
    }


    // Fonction qui s'exécute quand on clique sur le bouton envoyer
    const sumbitForm = (e) => {
        e.preventDefault();

        changeField({target: {name: 'subject'}}, true);
        changeField({target: {name: 'comment'}}, true);

        // On vérifie que tous les champs sont bon
        if(subject.check && text.check) {
            axios.post('/back/contact', {
                subject: subject.text,
                text: text.text
            })
                .then(function (response) {
                    // On vérifie si le backend nous retourne un message d'erreur ou non
                    if(response.data.status) {
                        setMessage(Libs.textValide(response.data.message));
                        setSubject({text: '', check: false, error: ''});
                        setText({text: '', check: false, error: ''});
                    }
                    else {
                        if(response.data.connected) {
                            setMessage(Libs.textError(response.data.message));
                        }
                        else {
                            window.location.reload();
                        }
                    }
                })
                .catch(function (error) {
                    axios.post('/back/error', {
                        code: 2000,
                        value: error
                    }).then(function (response) {});
                });
        }
    }

    return (
        <div className="pageContent">
            <div className="center">{message}</div>
            <form className='form' onSubmit={sumbitForm}>
                <Forms type='text' value={subject.text} name="subject" func={(e) => changeField(e, false)}>Sujet</Forms>
                {subject.error}
                <br/>
                <Forms type='textarea' value={text.text} name="comment" func={(e) => changeField(e, false)}>Message</Forms>
                {text.error}
                <br/>
                <div className="center">
                    <Forms type='submit'>Envoyer</Forms>
                </div>
            </form>
        </div>
    );
}

export default FormContact;