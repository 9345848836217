import Libs from "../../Libs";

const TrackingDetailsMessage = (props) => {

    const {tracking_messages_content, tracking_messages_date} = props.data;
    const {color} = props;

    return (
        <tr className={color ? 'backTr' : ''}>
            <td>
                {Libs.showDate(tracking_messages_date)}
            </td>
            <td className="leftBar">
                {tracking_messages_content}
            </td>
        </tr>
    )
}

export default TrackingDetailsMessage;