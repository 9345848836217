import {memo, useEffect, useState} from "react";
import Forms from "../format/Forms";
import Libs from "../../Libs";
import {Link} from "react-router-dom";
import axios from "axios";

const FormTrackingAdd = () => {

    const [name, setName] = useState({text: '', check: true, error: ''});
    const [reference, setReference] = useState({text: '', check: false, error: ''});
    const [companies, setCompanies] = useState({values: [], choice: 0, check: false, error: ''});
    const [groups, setGroups] = useState({values: [], choice: 0, check: true, error: ''});
    const [comment, setComment] = useState({text: '', check: true, error: ''});
    const [message, setMessage] = useState('');

    useEffect(() => {
        axios.get('/back/compagnies/get')
            .then(function (response) {
                // On vérifie si le backend nous retourne un message d'erreur ou non
                if(response.data.status) {
                    setMessage(Libs.textValide(response.data.message));
                    setCompanies({values: response.data.values, choice: 0, check: false, error: ''});
                }
                else {
                    if(response.data.connected) {
                        setMessage(Libs.textError(response.data.message));
                    }
                    else {
                        window.location.reload();
                    }
                }
            })
            .catch(function (error) {
                axios.post('/back/error', {
                    code: 2000,
                    value: error
                }).then(function (response) {});
            });

        axios.post('/back/groups/get')
            .then(function (response) {
                // On vérifie si le backend nous retourne un message d'erreur ou non
                if(response.data.status) {
                    setGroups({values: response.data.values, choice: 0, check: true, error: ''})
                }
                else {
                    if(!response.data.connected) {
                        window.location.reload();
                    }
                }
            })
            .catch(function (error) {
                axios.post('/back/error', {
                    code: 2000,
                    value: error
                }).then(function (response) {});
            });
    }, [])

    // Fonction qui actualise le state quand on modifie la valeur des input
    const changeField = (e, submit) => {

        let result;
        let value;

        // Action en fonction du champ modifié
        switch (e.target.name) {
            case 'name':
                value = submit ? name.text : e.target.value
                result = Libs.checkField('nbCharac', [0, 45, value]);

                setName({
                    text: value,
                    check: result.check,
                    error: result.error})
                break;

            case 'reference':
                value = submit ? reference.text : e.target.value
                result = Libs.checkField('noempty', value);

                setReference({
                    text: value,
                    check: result.check,
                    error: result.error})
                break;

            case 'companies':
                value = submit ? companies.choice : e.target.value
                result = Libs.checkField('selectCompanies', value);

                setCompanies({
                    values: companies.values,
                    choice: value,
                    check: result.check,
                    error: result.error})
                break;

            case 'groups':
                value = submit ? groups.choice : e.target.value
                result = Libs.checkField('selectGroups', value);

                setGroups({
                    values: groups.values,
                    choice: value,
                    check: result.check,
                    error: result.error})
                break;

            case 'comment':
                value = submit ? comment.text : e.target.value
                result = Libs.checkField('nbCharac', [0, 65535, value]);

                setComment({
                    text: value,
                    check: result.check,
                    error: result.error})
                break;

            default:
                break;
        }
    }

    // Fonction qui s'exécute quand on clique sur le bouton valider
    const sumbitForm = (e) => {
        e.preventDefault();

        changeField({target: {name: 'name'}}, true);
        changeField({target: {name: 'reference'}}, true);
        changeField({target: {name: 'companies'}}, true);
        changeField({target: {name: 'groups'}}, true);
        changeField({target: {name: 'comment'}}, true);

        console.log(name.check, reference.check, companies.check, comment.check, groups.check)

        // On vérifie que tous les champs du formulaire sont ok
        if(name.check && reference.check && companies.check && comment.check && groups.check) {
            axios.post('/back/tracking/add', {
                name: name.text,
                reference: reference.text,
                companies: parseInt(companies.choice),
                groups: parseInt(groups.choice),
                comment: comment.text
            })
                .then(function (response) {
                    // On vérifie si le backend nous retourne un message d'erreur ou non
                    if(response.data.status) {
                        setMessage(Libs.textValide(response.data.message));
                        setName({text: '', check: true, error: ''});
                        setReference({text: '', check: false, error: ''});
                        setCompanies({values: companies.values, choice: 0, check: false, error: ''});
                        setGroups({values: groups.values, choice: 0, check: false, error: ''});
                        setComment({text: '', check: true, error: ''});
                    }
                    else {
                        if(response.data.connected) {
                            setMessage(Libs.textError(response.data.message));
                        }
                        else {
                            window.location.reload();
                        }
                    }
                })
                .catch(function (error) {
                    axios.post('/back/error', {
                        code: 2000,
                        value: error
                    }).then(function (response) {});
                });
        }
    }

    return (
        <div className="pageContent">
            <div className="center">{message}</div>
            <form className='form' onSubmit={sumbitForm}>
                <Forms type='text' value={name.text} name="name" func={(e) => changeField(e, false)}>Nom</Forms>
                {name.error}
                <br/>
                <Forms type='text' value={reference.text} name="reference" obligatory="true" func={(e) => changeField(e, false)}>Référence Livreur</Forms>
                {reference.error}
                <br/>
                <Forms type='selectCompanies' value={companies.values} name="companies" obligatory="true" func={(e) => changeField(e, false)} />
                {companies.error}
                <br/>
                <Forms type='selectGroups' value={groups.values} name="groups" func={(e) => changeField(e, false)} >Aucun</Forms>
                {groups.error}
                <br/>
                <Forms type='textarea' value={comment.text} name="comment" func={(e) => changeField(e, false)}>Commentaire</Forms>
                {comment.error}
                <br/>
                <Forms type='submit'>Ajouter</Forms><Link to="/tracking">Retour</Link>
            </form>
        </div>
    )
}

export default memo(FormTrackingAdd);