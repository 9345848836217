import Title from "../components/format/Title";
import FormLogin from "../components/forms/FormLogin";
import './pages.css';

const Login = (props) => {
    return (
        <div className='page minWidth'>
            <Title>Connexion</Title>
            <FormLogin data={{setUser: props.data.setUser, setConnected: props.data.setConnected}} />
        </div>
    )
}

export default Login;