import {memo, useEffect, useState} from "react";
import Title from "../../components/format/Title";
import {Link} from "react-router-dom";
import axios from "axios";

const AdminMenu = () => {

    const [site, setSite] = useState(0);
    const [script, setScript] = useState(0);

    // Récupération du nombre de logs du site
    useEffect(() => {
        axios.post('/back/admin/logs/get', {
            type: 'site',
            count: true
        })
            .then(function (response) {
                // On vérifie si le backend nous retourne un message d'erreur ou non
                if(response.data.status) {
                    setSite(response.data.values[0].nb_errors_site_id);
                }
                else {
                    if(!response.data.connected) {
                        window.location.reload();
                    }
                }
            })
            .catch(function (error) {
                axios.post('/back/error', {
                    code: 2000,
                    value: error
                }).then(function (response) {});
            });
    }, []);

    // Récupération du nombre de logs de script
    useEffect(() => {
        axios.post('/back/admin/logs/get', {
            type: 'script',
            count: true
        })
            .then(function (response) {
                // On vérifie si le backend nous retourne un message d'erreur ou non
                if(response.data.status) {
                    setScript(response.data.values[0].nb_errors_scripts_id);
                }
                else {
                    if(!response.data.connected) {
                        window.location.reload();
                    }
                }
            })
            .catch(function (error) {
                axios.post('/back/error', {
                    code: 2000,
                    value: error
                }).then(function (response) {});
            });
    }, []);

    return (
        <div className='page'>
            <Title>Menu Administration</Title>
            <Link to={'/admin/users'}>Utilisateurs</Link>
            <Link to={'/admin/communications'}>Communications</Link>
            <Link to={'/admin/requests'}>Demandes ajout livreur</Link>
            <Link to={'/admin/logs/site'}>Logs Site ({site})</Link>
            <Link to={'/admin/logs/script'}>Logs Script ({script})</Link>
        </div>
    );
}

export default memo(AdminMenu)