import Forms from "../format/Forms";
import Libs from "../../Libs";
import {useState} from "react";
import axios from "axios";
import {Link} from "react-router-dom";

const FormNewPassword = ({id, data_key}) => {

    const [password, setPassword] = useState({text: '', check: false, error: ''});
    const [passwordC, setPasswordC] = useState({text: '', check: false, error: ''});
    const [message, setMessage] = useState('');

    // Fonction qui actualise le state quand on modifie la valeur des input
    const changeField = (e, submit) => {

        let result;
        let value;

        // Action en fonction du champ modifié
        switch (e.target.name) {
            case 'password':
                value = submit ? password.text : e.target.value
                result = Libs.checkField('password', value);

                setPassword({
                    text: value,
                    check: result.check,
                    error: result.error})
                break;

            case 'passwordC':
                value = submit ? passwordC.text : e.target.value
                result = Libs.checkField('passwordC', [value, password.text]);

                setPasswordC({
                    text: value,
                    check: result.check,
                    error: result.error})
                break;

            default:
                break;
        }
    }

    const submitForm = (e) => {
        e.preventDefault();

        // On force la vérification de tous les champs
        changeField({target: {name: 'password'}}, true);
        changeField({target: {name: 'passwordC'}}, true);

        // Si tous les champs sont bon
        if(password.check && passwordC.check) {
            /* Requette vers le serveur backend
                .then si la requette vers le backend fonctionne bien
                .catch si une erreur de communication avec le backend
            */
            axios.post('/back/newPassword', {
                id: id === undefined ? null : id,
                key: data_key === undefined ? null : data_key,
                password: password,
                passwordC: passwordC
            })
                .then(function (response) {

                    // On vérifie si le backend nous retourne un message d'erreur ou non
                    if(response.data.status) {
                        setMessage(Libs.textValide(response.data.message));
                        setPassword({text: '', check: false, error: ''});
                        setPasswordC({text: '', check: false, error: ''});
                    }
                    else {
                        setMessage(Libs.textError(response.data.message));
                    }
                })
                .catch(function (error) {
                    axios.post('/back/error', {
                        code: 2000,
                        value: error
                    }).then(function (response) {});
                });
        }
    }

    return (
        <form onSubmit={submitForm}>
            <div className="center">
                {message}
            </div>
            <br/>
            <h3>Mot de Passe</h3>
            <br/>
            <Forms type='password' value={password.text} name="password" obligatory="true" func={(e) => changeField(e, false)} placeholder="********">Nouveau</Forms>
            {password.error}
            <br/>
            <Forms type='password' value={passwordC.text} name="passwordC" obligatory="true" func={(e) => changeField(e, false)} placeholder="********">Confirmation</Forms>
            {passwordC.error}
            <br/>
            <div className="center">
                <Forms type='submit'>Modifer</Forms>
                <br/>
                {id === undefined ? '' : (<Link to={'/login'} >Connexion</Link>)}
            </div>
        </form>
    );
}

export default FormNewPassword;