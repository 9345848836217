import {memo, useState} from "react";
import Title from "../../components/format/Title";
import Forms from "../../components/format/Forms";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import Libs from "../../Libs";

const AdminCommunication = () => {

    const [title, setTitle] = useState('');
    const [version, setVersion] = useState('');
    const [text, setText] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    // Fonction qui s'exécute quand on clique sur le bouton envoyer
    const sumbitForm = (e) => {
        e.preventDefault();

        setMessage(Libs.textValide('Envoie en cours...'));

        // On exécute la supression du tracking
        axios.post('/back/admin/communication/send', {
            title: title,
            version: version,
            text: text
        })
            .then(function (response) {

                // On vérifie si le backend nous retourne un message d'erreur ou non
                if(response.data.status) {
                    setMessage(Libs.textValide(response.data.message));
                    setTitle('');
                    setVersion('');
                    setText('');
                }
                else {
                    if(response.data.connected) {
                        setMessage(Libs.textError(response.data.message));
                    }
                    else {
                        window.location.reload();
                    }
                }
            })
            .catch(function (error) {
                axios.post('/back/error', {
                    code: 2000,
                    value: error
                }).then(function (response) {});
            });
    }

    return (
        <div className='page'>
            <Title>Communication</Title>
            {message}
            <form className='form' onSubmit={sumbitForm}>
                <Forms type='text' value={title} name="title" func={(e) => {setTitle(e.target.value)}}>Titre</Forms>
                <br/>
                <Forms type='text' value={version} name="version" func={(e) => {setVersion(e.target.value)}}>Version</Forms>
                <br/>
                <Forms type='textarea' value={text} name="message" func={(e) => {setText(e.target.value)}}>Message</Forms>
                <br/>
                <Forms type='submit'>Envoyer</Forms> <a href="/" onClick={(e) => {e.preventDefault(); navigate(-1)}}>Retour</a>
            </form>
        </div>
    );
}

export default memo(AdminCommunication)