import Title from "../components/format/Title";
import './pages.css';
import FormNotifications from "../components/forms/FormNotifications";
import FormSettings from "../components/forms/FormSettings";
import LoginHistory from "../components/blocs/LoginHistory";
import {useState} from "react";

const Settings = ({user}) => {

    const [showLog, setShowLog] = useState(false);

    return (
        <div className='page'>
            <Title>Paramètres</Title>
            <FormNotifications user={user} />
            <br/>
            <FormSettings setShowLog={setShowLog} />
            {showLog ? (<><br/><LoginHistory /></>) : ''}
        </div>
    );
}

export default Settings;