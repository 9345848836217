import {memo, useState} from "react";
import axios from "axios";
import Forms from "../format/Forms";
import {Link} from "react-router-dom";
import Libs from '../../Libs';
import ReCAPTCHA from "react-google-recaptcha";

const FormRegister = () => {

    // Déclaration des varaibles states de chaque champs du formulaire
    const [firstName, setFirstName] = useState({text: '', check: false, error: ''});
    const [secondName, setSecondName] = useState({text: '', check: false, error: ''});
    const [email, setEmail] = useState({text: '', check: false, error: ''});
    const [password, setPassword] = useState({text: '', check: false, error: ''});
    const [passwordC, setPasswordC] = useState({text: '', check: false, error: ''});
    const [phone, setPhone] = useState({text: '', check: true, error: ''});
    const [boxCGU, setBoxCGU] = useState({text: false, check: false, error: ''});
    const [reCaptcha, setRecaptcha] = useState(false);
    const [message, setMessage] = useState({msg1: '', msg2: ''});

    // Fonction qui actualise le state quand on modifie la valeur des input
    const changeField = (e, submit) => {

        let result;
        let value;

        // Action en fonction du champ modifié
        switch (e.target.name) {
            case 'firstName':
                value = submit ? firstName.text : e.target.value
                result = Libs.checkField('name', value);

                setFirstName({
                    text: value.charAt(0).toUpperCase() + value.slice(1),
                    check: result.check,
                    error: result.error})
                break;

            case 'secondName':
                value = submit ? secondName.text : e.target.value
                result = Libs.checkField('name', value);

                setSecondName({
                    text: value.toUpperCase(),
                    check: result.check,
                    error: result.error})
                break;

            case 'email':
                value = submit ? email.text : e.target.value
                result = Libs.checkField('email', value);

                setEmail({
                    text: value,
                    check: result.check,
                    error: result.error})
                break;

            case 'password':
                value = submit ? password.text : e.target.value
                result = Libs.checkField('password', value);

                setPassword({
                    text: value,
                    check: result.check,
                    error: result.error})
                break;

            case 'passwordC':
                value = submit ? passwordC.text : e.target.value
                result = Libs.checkField('passwordC', [value, password.text]);

                setPasswordC({
                    text: value,
                    check: result.check,
                    error: result.error})
                break;

            case 'phone':
                value = submit ? phone.text : e.target.value
                result = Libs.checkField('phone', value);

                setPhone({
                    text: value,
                    check: result.check,
                    error: result.error})
                break;

            case 'boxCGU':
                value = submit ? boxCGU.text : e.target.checked
                result = Libs.checkField('checkbox', value);

                setBoxCGU({
                    text: value,
                    check: result.check,
                    error: result.error})
                break;

            default:
                break;
        }
    }

    // Fonction qui s'exécute quand on clique sur le bouton valider
    const sumbitForm = (e) => {
        e.preventDefault();

        // On force la vérification de tous les champs
        changeField({target: {name: 'firstName'}}, true);
        changeField({target: {name: 'secondName'}}, true);
        changeField({target: {name: 'email'}}, true);
        changeField({target: {name: 'password'}}, true);
        changeField({target: {name: 'passwordC'}}, true);
        changeField({target: {name: 'phone'}}, true);
        changeField({target: {name: 'boxCGU'}}, true);

        if(!reCaptcha) {
             setMessage({msg1: Libs.textError('Le captcha doit être validé !'), msg2: ''});
        }
        else {
            setMessage({msg1: '', msg2: ''});
        }

        // Si tous les champs sont bon
        if(firstName.check && secondName.check && email.check && password.check && passwordC.check && phone.check && boxCGU.check && reCaptcha) {
            /* Requette vers le serveur backend
                .then si la requette vers le backend fonctionne bien
                .catch si une erreur de communication avec le backend
            */
            axios.post('/back/register', {
                firstName: firstName,
                secondName: secondName,
                email: email,
                password: password,
                passwordC: passwordC,
                phone: phone,
                boxCGU: boxCGU
            })
                .then(function (response) {

                    // On vérifie si le backend nous retourne un message d'erreur ou non
                    if(response.data.status) {
                        setMessage({msg1: Libs.textValide("Inscription Terminée"), msg2: Libs.textValide("(Vous avez reçu un lien d'activation par mail)")});
                        setFirstName({text: '', check: false, error: ''});
                        setSecondName({text: '', check: false, error: ''});
                        setEmail({text: '', check: false, error: ''});
                        setPassword({text: '', check: false, error: ''});
                        setPasswordC({text: '', check: false, error: ''});
                        setPhone({text: '', check: false, error: ''});
                        setBoxCGU({text: false, check: false, error: ''});
                    }
                    else {
                        setMessage({msg1: Libs.textError(response.data.message), msg2: ''});
                    }
                })
                .catch(function (error) {
                    axios.post('/back/error', {
                        code: 2000,
                        value: error
                    }).then(function (response) {});
                });
        }
    }

    // Affichage du formulaire d'inscription
    return (
        <>
            <div className="center">{message.msg1}<br/>{message.msg2}</div>
            <form onSubmit={sumbitForm}>
                <Forms type='text' value={firstName.text} name="firstName" obligatory="true" func={(e) => changeField(e, false)} placeholder="Robert">Prénom</Forms>
                {firstName.error}
                <br />
                <Forms type='text' value={secondName.text} name="secondName" obligatory="true" func={(e) => changeField(e, false)} placeholder="Petit">Nom</Forms>
                {secondName.error}
                <br />
                <Forms type='email' value={email.text} name="email" obligatory="true" func={(e) => changeField(e, false)} placeholder="robert.petit@mail.fr">Mail</Forms>
                {email.error}
                <br />
                <Forms type='password' value={password.text} name="password" obligatory="true" func={(e) => changeField(e, false)} placeholder="********">Mot de Passe</Forms>
                {password.error}
                <br />
                <Forms type='password' value={passwordC.text} name="passwordC" obligatory="true" func={(e) => changeField(e, false)} placeholder="********">Confirmation</Forms>
                {passwordC.error}
                <br />
                <Forms type="tel" value={phone.text} name="phone" func={(e) => changeField(e, false)} placeholder="0766604454">Téléphone</Forms>
                {phone.error}
                <br />
                <Forms type="checkbox" value={boxCGU.text} name="boxCGU" func={(e) => changeField(e, false)}>J'ai lu et j'accepte les <Link target="_blank" to={'/cgu'} >Conditions Générales d'Utilisation</Link></Forms>
                <br/>
                {boxCGU.error}
                <br/>
                <br/>
                <ReCAPTCHA sitekey="6LeYgnYiAAAAAL-OE1qFX_kdhcmwCDqYnOyX9HQX" onChange={() => setRecaptcha(true)}/>
                <br />
                <div className="center">
                    <Forms type='submit'>Valider</Forms>
                    <Link className="linkLoRI" to={'/login'} >Connexion</Link>
                </div>
                <span className="obligatory">*</span> = Obligatoire
            </form>
        </>
    )
}

export default memo(FormRegister);