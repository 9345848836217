import {memo, useEffect, useState} from "react";
import Libs from "../../Libs";
import Forms from "../format/Forms";
import axios from "axios";
import {Link, useNavigate} from "react-router-dom";
import ErrorPage from "../../pages/ErrorPage";

const FormGroupEdit = (props) => {

    const [acces, setAccess] = useState(false);
    const [show, setShow] = useState(false);
    const [name, setName] = useState({text: '', check: false, error: ''});
    const [groups, setGroups] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        axios.post('/back/groups/getEdit', {
            groups_id: props.groups_id
        })
            .then(function (response) {
                // On vérifie si le backend nous retourne un message d'erreur ou non
                if(response.data.status) {
                    props.infos('');
                    setGroups(response.data.groups[0]);
                    setName({text: response.data.groups[0].groups_name, check: true, error: ''});
                    setAccess(true);
                }
                else {
                    if(!response.data.connected) {
                        window.location.reload();
                    }
                }

                setShow(true);
            })
            .catch(function (error) {
                axios.post('/back/error', {
                    code: 2000,
                    value: error
                }).then(function (response) {});
            });
        // eslint-disable-next-line
    }, [props.groups_id])

    // Fonction qui actualise le state quand on modifie le champ name
    const changeName = (e, submit) => {

        let result;
        let value;

        value = submit ? name.text : e.target.value
        result = Libs.checkField('nbCharac', [1, 30, value]);

        setName({
            text: value,
            check: result.check,
            error: result.error});
    }

    // Fonction qui s'exécute quand on clique sur le bouton valider
    const sumbitForm = (e) => {
        e.preventDefault();

        changeName({target: {name: 'name'}}, true);

        // On vérifie que le champ name est ok
        if(name.check) {
            axios.post('/back/groups/edit', {
                groups_id: groups.groups_id,
                name: name.text
            })
                .then(function (response) {
                    // On vérifie si le backend nous retourne un message d'erreur ou non
                    if(response.data.status) {
                        props.infos(Libs.textValide(response.data.message));
                        props.setUpdate(props.update ? false : true);
                        navigate('/groups');
                    }
                    else {
                        if(response.data.connected) {
                            props.infos(Libs.textError(response.data.message));
                        }
                        else {
                            window.location.reload();
                        }
                    }
                })
                .catch(function (error) {
                    axios.post('/back/error', {
                        code: 2000,
                        value: error
                    }).then(function (response) {});
                });
        }
    }

    if(show) {
        if(acces) {
            return (
                <form className='form' onSubmit={sumbitForm}>
                    <Forms type='text' value={name.text} name="name" func={(e) => changeName(e, false)}>Nouveau nom</Forms>
                    {name.error}
                    <br/>
                    <div className="center"><Forms type='submit'>Modifier</Forms> <Link to={"/groups"} >Annuler</Link></div>
                </form>
            );
        }
        else {
            return <ErrorPage />
        }
    }
}

export default memo(FormGroupEdit)