import {memo} from "react";
import './menu.css'
import {NavLink} from "react-router-dom";
import logout_icon from '../../assets/icons/logout.png'
import axios from "axios";

const Menu = ({rang}) => {

    const logout = () => {
        axios.get('/back/logout')
            .then(function (response) {

                // Si la déconnexion est bonne on reload la page
                if(response.data.status) {
                    window.location.reload();
                }
            })
            .catch(function (error) {
                axios.post('/back/error', {
                    code: 2000,
                    value: error
                }).then(function (response) {});
            });
    }

    const hamburger = () => {

        const nb = document.getElementsByTagName('li').length;

        for(let i = 0; i < nb; i++) {
            if(document.getElementsByTagName('li')[i].style.display !== 'block') {
                document.getElementsByTagName('li')[i].style.display = 'block';
            }
            else {
                document.getElementsByTagName('li')[i].style.display = 'none';
            }
        }
    }

    if (rang === 5 || rang === 1) {
        return (
            <nav>
                <button id="hamburger" onClick={hamburger}>&#9776;</button>
                <ul>
                    <li><NavLink to='/tracking'>Tracking</NavLink></li>
                    <li><NavLink to='/groups'>Groupes</NavLink></li>
                    <li><NavLink to='/account'>Mon Compte</NavLink></li>
                    <li><NavLink to='/settings'>Paramètres</NavLink></li>
                    <li><NavLink to='/news'>News</NavLink></li>
                    <li><NavLink to='/contact'>Contact</NavLink></li>
                    {rang === 1 ? <li><NavLink to='/admin'>Administration</NavLink></li> : ""}
                    <li><img className="logout" onClick={logout} alt={"logout"} src={logout_icon}/></li>
                </ul>
            </nav>
        )
    }
    else {
        return null;
    }
}

export default memo(Menu);