import './app.css';
import './fonts.css'
import {memo, useEffect, useState} from "react";
import {BrowserRouter, Route, Routes, Navigate} from "react-router-dom"
import Menu from "./components/header/Menu";
import Login from "./pages/Login";
import ErrorPage from "./pages/ErrorPage";
import Register from "./pages/Register";
import CGU from "./pages/CGU";
import Confirmation from "./pages/Confirmation";
import axios from "axios";
import TrackingView from "./pages/TrackingView";
import TrackingAdd from "./pages/TrackingAdd";
import TrackingDetails from "./pages/TrackingDetails";
import TrackingDelete from "./pages/TrackingDelete";
import TrackingEdit from "./pages/TrackingEdit";
import GroupView from "./pages/GroupView";
import HeadbandNews from "./components/header/HeadbandNews";
import Account from "./pages/Account";
import ResetPassword from "./pages/ResetPassword";
import Settings from "./pages/Settings";
import Contact from "./pages/Contact";
import AdminMenu from "./pages/admin/AdminMenu";
import AdminUsers from "./pages/admin/AdminUsers";
import UserDelete from "./pages/UserDelete";
import AdminUsersEdit from "./pages/admin/AdminUsersEdit";
import AdminCommunication from "./pages/admin/AdminCommunication";
import AdminRequests from "./pages/admin/AdminRequests";
import FormRequestsDelete from "./components/forms/FormRequestsDelete";
import AdminLogs from "./pages/admin/AdminLogs";
import News from "./pages/News";


const App = () => {

    const [user, setUser] = useState();
    const [settings, setSettings] = useState({settings_site_version: '', settings_site_maintenance: 0, settings_site_headband: null});
    const [connected, setConnected] = useState(false);
    const [sessionReq, setSessionReq] = useState(false);
    const [trackView, setTrackView] = useState({group: 0});

    useEffect(() => {
        // On demande au backend de vérifier si le couple ID et KEY est bon
        axios.get('/back/session')
            .then(function (response) {
                // On vérifie si le backend nous retourne un message d'erreur ou non
                if(response.data.status) {
                    setUser(response.data.user);
                    setConnected(true);
                }

                setSessionReq(true);
            })
            .catch(function (error) {
                axios.post('/back/error', {
                    code: 2000,
                    value: error
                }).then(function (response) {});
            });


        // On demande au backend de récupérer les settings du site
        axios.get('/back/settings-site')
            .then(function (response) {
                // On vérifie si le backend nous retourne un message d'erreur ou non
                if(response.data.status) {
                    setSettings(response.data.settings[0]);
                }
            })
            .catch(function (error) {
                axios.post('/back/error', {
                    code: 2000,
                    value: error
                }).then(function (response) {});
            });
        // eslint-disable-next-line
    }, []);


    let deferredPrompt;
    const addBtn = document.querySelector('.add-button');

    window.addEventListener('beforeinstallprompt', (e) => {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later.
        deferredPrompt = e;

        addBtn.classList.add('mobile');

        addBtn.addEventListener('click', (e) => {
            // hide our user interface that shows our A2HS button
            addBtn.classList.remove('mobile');
            // Show the prompt
            deferredPrompt.prompt();
            // Wait for the user to respond to the prompt
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                } else {
                    console.log('User dismissed the A2HS prompt');
                }
                deferredPrompt = null;
            });
        });
    });

    if(sessionReq) {
        if(connected)
        {
            let admin = '';
            if(user.grade === 1) {
                admin = (<Route path="/admin">
                    <Route path="" element={<AdminMenu />} />
                    <Route path="users">
                        <Route path="" element={<AdminUsers />} />
                        <Route path="edit/:email" element={<AdminUsersEdit />} />
                        <Route path="delete/:email" element={<UserDelete user={user} />} />
                    </Route>
                    <Route path="communications" element={<AdminCommunication />} />
                    <Route path="requests" element={<AdminRequests />} />
                    <Route path="requests/delete/:id" element={<FormRequestsDelete />} />
                    <Route path="logs/site" element={<AdminLogs type="site" />} />
                    <Route path="logs/script" element={<AdminLogs type="script" />} />
                </Route>)
            }

            return (
                <BrowserRouter>
                    <button className="add-button">Ajouter Doudou Tracking à l'écran d'accueil</button>
                    <HeadbandNews data={settings.settings_site_headband} />
                    <Menu rang={user.grade}/>
                    <Routes>
                        <Route path="/login" element={<Navigate to="/tracking" replace />} />
                        <Route path="/" element={<Navigate to="/tracking" replace />} />
                        <Route path="/tracking">
                            <Route path="" element={<TrackingView data={trackView} edit={setTrackView} />} />
                            <Route path="add" element={<TrackingAdd />} />
                            <Route path="details/:id" element={<TrackingDetails />} />
                            <Route path="edit/:id" element={<TrackingEdit />} />
                            <Route path="delete/:id" element={<TrackingDelete />} />
                        </Route>
                        <Route path="/groups">
                            <Route path="" element={<GroupView />} />
                            <Route path=":action/:id" element={<GroupView />} />
                        </Route>
                        <Route path="/account">
                            <Route path="" element={<Account user={user} setUser={setUser} />} />
                            <Route path="delete" element={<UserDelete user={user} />} />
                        </Route>
                        <Route path="/settings" element={<Settings user={user} />} />
                        <Route path={"/news"} element={<News />} />
                        <Route path="/contact" element={<Contact />} />
                        {
                            admin
                        }
                        <Route path="*" element={<ErrorPage />} />
                    </Routes>
                </BrowserRouter>
            );
        }
        else
        {
            return (
                <BrowserRouter>
                    <button className="add-button">Ajouter Doudou Tracking à l'écran d'accueil</button>
                    <Routes>
                        <Route path="/login" element={<Login data={{setUser: setUser, setConnected: setConnected}} />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/confirmation/:id/:key" element={<Confirmation />} />
                        <Route path="/reset-password" element={<ResetPassword />} />
                        <Route path="/reset-password/:id/:key" element={<ResetPassword />} />
                        <Route path="/cgu" element={<CGU />} />
                        <Route path="*" element={<Navigate to="/login" replace />} />
                    </Routes>
                </BrowserRouter>
            );
        }
    }
}

export default memo(App);
