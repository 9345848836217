import {memo} from "react";
import './headbandNews.css'

const HeadbandNews = ({data}) => {

    if (data !== null) {
        return (
            <div className="headBand">
                {data}
            </div>
        );
    }
    else {
        return null;
    }
}

export default memo(HeadbandNews);