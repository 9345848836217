import {memo, useState} from "react";
import Libs from "../../Libs";
import Forms from "../format/Forms";
import axios from "axios";

const FormGroupAdd = (props) => {
    const [name, setName] = useState({text: '', check: false, error: ''});

    // Fonction qui actualise le state quand on modifie le champ name
    const changeName = (e, submit) => {

        let result;
        let value;

        value = submit ? name.text : e.target.value
        result = Libs.checkField('nbCharac', [1, 30, value]);

        setName({
            text: value,
            check: result.check,
            error: result.error});
    }

    // Fonction qui s'exécute quand on clique sur le bouton valider
    const sumbitForm = (e) => {
        e.preventDefault();

        changeName({target: {name: 'name'}}, true);

        // On vérifie que le champ name est ok
        if(name.check) {
            axios.post('/back/groups/add', {
                name: name.text
            })
                .then(function (response) {
                    // On vérifie si le backend nous retourne un message d'erreur ou non
                    if(response.data.status) {
                        props.infos(Libs.textValide(response.data.message));
                        props.setUpdate(props.update ? false : true);
                        setName({text: '', check: true, error: ''});
                    }
                    else {
                        if(response.data.connected) {
                            props.infos(Libs.textError(response.data.message));
                        }
                        else {
                            window.location.reload();
                        }
                    }
                })
                .catch(function (error) {
                    axios.post('/back/error', {
                        code: 2000,
                        value: error
                    }).then(function (response) {});
                });
        }
    }

    return (
        <form className='form' onSubmit={sumbitForm}>
            <Forms type='text' value={name.text} name="name" func={(e) => changeName(e, false)}>Nom</Forms>
            {name.error}
            <br/>
            <div className="center"><Forms type='submit'>Ajouter</Forms></div>
        </form>
    );
}

export default memo(FormGroupAdd)