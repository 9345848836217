import {useNavigate, Link} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import Libs from "../../Libs";
import ErrorPage from "../../pages/ErrorPage";

const FormGroupDelete = (props) => {

    const [acces, setAccess] = useState(false);
    const [show, setShow] = useState(false);
    const [groups, setGroups] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        axios.post('/back/groups/getEdit', {
            groups_id: props.groups_id
        })
            .then(function (response) {
                // On vérifie si le backend nous retourne un message d'erreur ou non
                if(response.data.status) {
                    props.infos('');
                    setGroups(response.data.groups[0]);
                    setAccess(true);
                }
                else {
                    if(!response.data.connected) {
                        window.location.reload();
                    }
                }

                setShow(true);
            })
            .catch(function (error) {
                axios.post('/back/error', {
                    code: 2000,
                    value: error
                }).then(function (response) {});
            });
        // eslint-disable-next-line
    }, [props.groups_id])

    // Fonction qui s'exécute quand on clique sur le bouton valider
    const sumbitForm = (e) => {
        e.preventDefault();

        // On exécute la supression du tracking
        axios.post('/back/groups/delete', {
            groups_id: groups.groups_id
        })
            .then(function (response) {

                // On vérifie si le backend nous retourne un message d'erreur ou non
                if(response.data.status) {
                    props.infos(Libs.textValide(response.data.message));
                    props.setUpdate(props.update ? false : true);
                    navigate('/groups');
                }
                else {
                    if(response.data.connected) {
                        props.infos(Libs.textError(response.data.message));
                    }
                    else {
                        window.location.reload();
                    }
                }
            })
            .catch(function (error) {
                axios.post('/back/error', {
                    code: 2000,
                    value: error
                }).then(function (response) {});
            });
    }

    if(show) {
        if (acces) {
            return (
                <div className="center">
                    <h2>Êtes-vous sûr de vouloir supprimer le groupe: {groups.groups_name} ?</h2>
                    <br/>
                    <button className="deleteColor" onClick={sumbitForm}>Supprimer</button> <Link to={'/groups'} >Annuler</Link>
                </div>
            );
        } else {
            return <ErrorPage/>;
        }
    }
}

export default FormGroupDelete;