import Title from "../components/format/Title";
import './pages.css';
import FormContact from "../components/forms/FormContact";
import Forms from "../components/format/Forms";
import {useState} from "react";
import Libs from "../Libs";
import ErrorPage from "./ErrorPage";
import FormAddRequest from "../components/forms/FormAddRequest";

const Contact = () => {

    const [object, setObject] = useState({values: ["Demande d'ajout service livraison", "Autres demandes"], choice: 1, check: false, error: ''});

    // Fonction qui actualise le state quand on modifie la valeur des input
    const selectObject = (e) => {

        let result;
        let value;

        result = Libs.checkField('selectGroups', value);

        setObject({
            values: object.values,
            choice: parseInt(e.target.value),
            check: result.check,
            error: result.error});
    }

    return (
        <div className='page'>
            <Title>Contact</Title>
            <Forms type='selectListName' value={object.values} name="object" func={selectObject} />
            <br/>
            {object.choice === 1 ? <FormAddRequest /> : (object.choice === 2 ? <FormContact /> : <ErrorPage />)}
        </div>
    )
}

export default Contact;