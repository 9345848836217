import {memo, useEffect, useState} from "react";
import Title from "../components/format/Title";
import axios from "axios";
import Libs from "../Libs";

const News = () => {

    const [news, setNews] = useState([]);

    useEffect(() => {
        axios.post('/back/admin/communication/get')
            .then(function (response) {
                // On vérifie si le backend nous retourne un message d'erreur ou non
                if(response.data.status) {
                    setNews(response.data.values);
                }
                else {
                    if(!response.data.connected) {
                        window.location.reload();
                    }
                }
            })
            .catch(function (error) {
                axios.post('/back/error', {
                    code: 2000,
                    value: error
                }).then(function (response) {});
            });
    }, []);

    let show_news = (
        news.map((value, index) => {
            const text = () => {
                return {__html: value.news_message}
            }

            return (
                <div className="news">
                    <h2>{value.news_title}</h2>
                    <br/>
                    <div dangerouslySetInnerHTML={text()}></div>
                    <br/>
                    <br/>
                    <span className="small-text">Version {value.news_version_site}</span>
                    <br/>
                    <span className="small-text">Le {Libs.showDate(value.news_date)}</span>
                </div>
            );
        })
    );

    return (
        <div className='page'>
            <Title>News</Title>
            {show_news}
        </div>
    );
}

export default memo(News)