import {useParams, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import Libs from "../Libs";
import ErrorPage from "./ErrorPage";
import Title from "../components/format/Title";

const UserDelete = (props) => {

    const [acces, setAccess] = useState(false);
    const [show, setShow] = useState(false);
    const [user, setUser] = useState([]);
    const [message, setMessage] = useState('');
    const {email} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        // On va récupérer les infos de l'utilisateur à supprimer
        axios.post('/back/admin/user/get', {
            search: email === undefined ? props.user.email : decodeURI(email)
        })
            .then(function (response) {
                // On vérifie si le backend nous retourne un message d'erreur ou non
                if (response.data.status) {
                    setMessage(Libs.textValide(response.data.message));
                    setShow(true);

                    if(response.data.values.length === 1) {
                        setUser(response.data.values[0])
                        setAccess(true);
                    }
                } else {
                    if (response.data.connected) {
                        setMessage(Libs.textError(response.data.message));
                    } else {
                        window.location.reload();
                    }
                }
            })
            .catch(function (error) {
                axios.post('/back/error', {
                    code: 2000,
                    value: error
                }).then(function (response) {
                });
            });
    }, []);

    // Fonction qui s'exécute quand on clique sur le bouton supprimer
    const sumbitForm = (e) => {
        e.preventDefault();

        // On exécute la supression du tracking
        axios.post('/back/admin/user/delete', {
            user_id: user.users_id
        })
            .then(function (response) {

                // On vérifie si le backend nous retourne un message d'erreur ou non
                if(response.data.status) {
                    navigate('../');
                }
                else {
                    if(response.data.connected) {
                        setMessage(Libs.textError(response.data.message));
                    }
                    else {
                        window.location.reload();
                    }
                }
            })
            .catch(function (error) {
                axios.post('/back/error', {
                    code: 2000,
                    value: error
                }).then(function (response) {});
            });
    }

    if(show) {
        if (acces) {

            let mesgInfo = '';

            if(user.users_id === props.user.id) {
                mesgInfo = 'Êtes-vous sûr de vouloir supprimer définitivement votre compte ?';
            }
            else {
                if(props.user.grade === 1) {
                    mesgInfo = `Êtes-vous sûr de vouloir supprimer définitivement l'utilisateur ${user.users_first_name} ${user.users_second_name} ?`;
                }
                else {
                    setAccess(false);
                }
            }

            return (
                <div className='page'>
                    <Title>Attention !</Title>
                    {message}
                    <h2>{mesgInfo}</h2>
                    <br/>
                    <div>
                        <button className="deleteColor" onClick={sumbitForm}>Supprimer</button> <a href="/" onClick={(e) => {e.preventDefault(); navigate(-1)}}>Annuler</a>
                    </div>
                </div>
            );
        } else {
            return <ErrorPage/>;
        }
    }
}

export default UserDelete;