import Title from "../components/format/Title";
import {useEffect, useState, memo} from "react";
import axios from "axios";
import Libs from "../Libs";
import {Link, useParams} from "react-router-dom";
import TrackingDetailsMessage from "../components/blocs/TrackingDetailsMessage";
import icon_new from "../assets/icons/new.png";
import icon_transit from "../assets/icons/transit.png";
import icon_delivered from "../assets/icons/delivered.png";
import icon_get_back from "../assets/icons/get-back.png";
import icon_undelivered from "../assets/icons/undelivered.png";
import icon_alert from "../assets/icons/alert.png";
import icon_unknown from "../assets/icons/unknown.png";
import icon_edit from "../assets/icons/edit.png";
import ErrorPage from "./ErrorPage";

const TrackingDetails = () => {

    const [acces, setAccess] = useState(false);
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState('');
    const [details, setDetails] = useState([]);
    const [tracking, setTracking] = useState([{trackings_id: null, trackings_reference: null, trackings_name: null, trackings_date_add: null, status_name: null, status_icon: null, companies_name: null, trackings_date_submit: null, trackings_date_delivery: null, trackings_date_estimated_delivery: null, trackings_comment: null}]);
    const {trackings_id, trackings_reference, trackings_name, trackings_date_add, status_name, status_icon, companies_name, trackings_date_submit, trackings_date_delivery, trackings_date_estimated_delivery, trackings_comment} = tracking[0];
    const {id} = useParams();

    let showBloc = false;

    let img;

    switch(status_icon) {
        case 'new':
            img = icon_new;
            break;
        case 'transit':
            img = icon_transit;
            break;
        case 'delivered':
            img = icon_delivered;
            break;
        case 'get-back':
            img = icon_get_back;
            break;
        case 'undelivered':
            img = icon_undelivered;
            break;
        case 'alert':
            img = icon_alert;
            break;
        case 'unknown':
            img = icon_unknown;
            break;
        default:
            break;
    }

    let date_delivery = '';
    if(Libs.showDate(trackings_date_delivery) !== 'NC') {
        showBloc = true;
        date_delivery = (
            <>
                <h3>Livré le</h3> {Libs.showDate(trackings_date_delivery)}
                <br/>
            </>
        );
    }
    else {

    }

    let date_submit = '';
    if(Libs.showDate(trackings_date_submit) !== 'NC') {
        showBloc = true;
        date_submit = (
            <>
                <h3>Envoyé le</h3> {Libs.showDate(trackings_date_submit)}
                <br/>
            </>
        );
    }
    else {

    }

    let date_estimated = '';
    if(Libs.showDate(trackings_date_estimated_delivery) !== 'NC') {
        showBloc = true;
        date_estimated = (
            <>
                <h3>Livraison estimée le</h3> {Libs.showDate(trackings_date_estimated_delivery).substring(0, 10)}
                <br/>
            </>
        );
    }

    let comment = '';
    if(trackings_comment !== null) {
        comment = (
            <div className="trackingBloc">
                <h3>Commentaire:</h3>
                <br/>
                {trackings_comment}
                <br/>
            </div>
        );
    }

    let bloc = '';
    if(showBloc) {
        bloc = (<div className="trackingBloc">
                    {date_submit}
                    {date_estimated}
                    {date_delivery}
                </div>
        );
    }

    useEffect(() => {
        // On va récupérer la liste des messages du tracking
        axios.post('/back/tracking/details', {
            tracking_id: id
        })
            .then(function (response) {

                // On vérifie si le backend nous retourne un message d'erreur ou non
                if(response.data.status) {
                    setAccess(true);
                    setDetails(response.data.details)
                    setTracking(response.data.tracking)
                }
                else {
                    if(response.data.connected) {
                        setMessage(Libs.textError(response.data.message));
                    }
                    else {
                        window.location.reload();
                    }
                }

                setShow(true);
            })
            .catch(function (error) {
                axios.post('/back/error', {
                    code: 2000,
                    value: error
                }).then(function (response) {});
            });
        // eslint-disable-next-line
    }, [])

    let ligne = false;

    if(show) {
        if(acces) {
            return (
                <div className='page'>
                    <Title>Détails</Title>
                    {message}
                    <h2><img className="icon" alt={status_icon} src={img} /> <Link to={`/tracking/details/${trackings_id}`} >{trackings_name}</Link> <Link to={`/tracking/edit/${trackings_id}`} ><img className="icon" alt="edit" src={icon_edit}/></Link></h2>
                    <br/>
                    <div className="content">
                        <div className="trackingBloc">
                            <h3>Référence:</h3> {trackings_reference}
                            <br/>
                            <h3>Status:</h3> {status_name}
                            <br/>
                            <h3>Livreur:</h3> {companies_name}
                            <br/>
                            <h3>Ajouté le</h3> {Libs.showDate(trackings_date_add)}
                        </div>
                        <br/>
                        {bloc}
                        <br/>
                        {comment}
                        <br/>
                        <h3>Détails:</h3>
                        <table>
                            <thead>
                            <tr>
                                <th>
                                    Date
                                </th>
                                <th>
                                    Message
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                details.map((value, index) => {
                                    ligne = ligne ? false : true;
                                    return <TrackingDetailsMessage key={index} data={value} color={ligne} />
                                })
                            }
                            </tbody>
                        </table>
                        <br/>
                        <div className="center">
                            <Link to={`/tracking/delete/${trackings_id}`} ><button className="deleteColor">Supprimer</button></Link>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return <ErrorPage />;
        }
    }
}

export default memo(TrackingDetails);