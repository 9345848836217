import {memo, useEffect, useState} from "react";
import Title from "../../components/format/Title";
import axios from "axios";
import Libs from "../../Libs";
import icon_delete from "../../assets/icons/delete.png";

const AdminLogs = ({type}) => {

    const [logs, setLogs] = useState([]);
    const [change, setChange] = useState(false);

    useEffect(() => {
        axios.post('/back/admin/logs/get', {
            type: type
        })
            .then(function (response) {
                // On vérifie si le backend nous retourne un message d'erreur ou non
                if(response.data.status) {
                    setLogs(response.data.values);
                }
                else {
                    if(!response.data.connected) {
                        window.location.reload();
                    }
                }
            })
            .catch(function (error) {
                axios.post('/back/error', {
                    code: 2000,
                    value: error
                }).then(function (response) {});
            });
        // eslint-disable-next-line
    }, [change]);

    const deleteRequest = (id) => {
        axios.post('/back/admin/logs/delete', {
            id: id,
            type: type
        })
            .then(function (response) {
                // On vérifie si le backend nous retourne un message d'erreur ou non
                if(response.data.status) {
                    setLogs(response.data.values);
                    setChange(change ? false : true)
                }
                else {
                    if(!response.data.connected) {
                        window.location.reload();
                    }
                }
            })
            .catch(function (error) {
                axios.post('/back/error', {
                    code: 2000,
                    value: error
                }).then(function (response) {});
            });
    }

    let list;
    let color = false;

    if(logs.length > 0) {
        if(type === 'site') {
            list = (
                <table>
                    <thead>
                        <tr>
                            <th>
                                Code
                            </th>
                            <th>
                                Utilisateur
                            </th>
                            <th>
                                IP
                            </th>
                            <th>
                                URL
                            </th>
                            <th>
                                Date
                            </th>
                            <th>
                                Contenu
                            </th>
                            <th>
                                Side
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        logs.map((value, index) => {
                            color = color ? false : true;

                            const user = value.users_first_name === null && value.users_second_name === null ? 'Inconnu' : `${value.users_first_name} ${value.users_second_name}`

                            return (
                                <tr key={index} className={color ? 'backTr' : ''}>
                                    <td className="tdGroups">
                                        {value.errors_site_code}
                                    </td>
                                    <td className="tdGroups">
                                        {user}
                                    </td>
                                    <td className="tdGroups">
                                        {value.errors_site_ip}
                                    </td>
                                    <td className="tdGroups">
                                        {value.errors_site_url}
                                    </td>
                                    <td className="tdGroups">
                                        {Libs.showDate(value.errors_site_date)}
                                    </td>
                                    <td className="tdGroups">
                                        <div className="tdLog">{value.errors_site_object}</div>
                                    </td>
                                    <td className="tdGroups">
                                        {value.errors_site_side}
                                    </td>
                                    <td className="leftBar tdGroups tdMin center">
                                        <img className="icon delete-log" alt='delete' onClick={(e) => {deleteRequest(e.target.id)}} id={value.errors_site_id} src={icon_delete}/>
                                    </td>
                                </tr>
                            );
                        })
                    }
                    </tbody>
                </table>
            );
        }
        else if(type === 'script') {
            list = (
                <table>
                    <thead>
                    <tr>
                        <th>
                            Tracking
                        </th>
                        <th>
                            Livreur
                        </th>
                        <th>
                            Date
                        </th>
                        <th>
                            Contenu
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        logs.map((value, index) => {
                            color = color ? false : true;

                            return (
                                <tr key={index} className={color ? 'backTr' : ''}>
                                    <td className="tdGroups">
                                        {value.trackings_reference}
                                    </td>
                                    <td className="tdGroups">
                                        {value.companies_name}
                                    </td>
                                    <td className="tdGroups">
                                        {Libs.showDate(value.errors_scripts_date)}
                                    </td>
                                    <td className="tdGroups">
                                        {value.errors_scripts_object}
                                    </td>
                                    <td className="leftBar tdGroups tdMin center">
                                        <img className="icon delete-log" alt='delete' onClick={(e) => {deleteRequest(e.target.id)}} id={value.errors_scripts_id} src={icon_delete}/>
                                    </td>
                                </tr>
                            );
                        })
                    }
                    </tbody>
                </table>
            );
        }
    }
    else {
        list = "Aucun logs trouvé";
    }

    return (
        <div className='page page-log'>
            <Title>Administration logs {type}</Title>
            <div className="center">
                <button className="deleteColor" onClick={(e) => {deleteRequest('%')}}>Supprimer Tout</button>
                <br/>
                {list}
            </div>
        </div>
    );
}

export default memo(AdminLogs)