import Title from "../components/format/Title";
import './pages.css';
import FormRegister from "../components/forms/FormRegister";

const Register = () => {
    return (
        <div className='page minWidth'>
            <Title>Inscription</Title>
            <FormRegister />
        </div>
    )
}

export default Register;