import {useParams} from "react-router-dom";
import FormNewPassword from "../components/forms/FormNewPassword";
import FormResetPassword from "../components/forms/FormResetPassword";
import ErrorPage from "./ErrorPage";

const ResetPassword = () => {

    const {id, key} = useParams();

    let show;

    if(id === undefined && key === undefined) {
        show = (<FormResetPassword />);
    }
    else {
        if(!isNaN(parseInt(id))) {
            show = (<FormNewPassword id={id} data_key={key} />);
        }
        else {
            show = (<ErrorPage />);
        }
    }

    return (
        <div className="page minWidth">
            {show}
        </div>
    );
}

export default ResetPassword;