import {memo, useEffect, useState} from 'react';
import ErrorPage from "./ErrorPage";
import axios from "axios";
import Libs from "../Libs";
import {useParams} from "react-router-dom";
import FormTrackingEdit from "../components/forms/FormTrackingEdit";
import Title from "../components/format/Title";

const TrackingEdit = () => {

    const [acces, setAccess] = useState(false);
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState('');
    const [tracking, setTracking] = useState([]);
    const [groups, setGroups] = useState([]);
    const {id} = useParams();

    useEffect(() => {
        // On va récupérer la liste des messages du tracking
        axios.post('/back/tracking/getEdit', {
            tracking_id: id
        })
            .then(function (response) {

                // On vérifie si le backend nous retourne un message d'erreur ou non
                if(response.data.status) {
                    setAccess(true);
                    setTracking(response.data.tracking);
                    setGroups(response.data.groups);
                }
                else {
                    if(response.data.connected) {
                        setMessage(Libs.textError(response.data.message));
                    }
                    else {
                        window.location.reload();
                    }
                }

                setShow(true);
            })
            .catch(function (error) {
                axios.post('/back/error', {
                    code: 2000,
                    value: error
                }).then(function (response) {});
            });
        // eslint-disable-next-line
    }, [])

    if(show) {
        if(acces) {
            return (
                <div className="page">
                    <Title>Editer</Title>
                    {message}
                    <FormTrackingEdit tracking={tracking} groups={groups}/>
                </div>
            );
        }
        else {
            return <ErrorPage />
        }
    }

}

export default memo(TrackingEdit);