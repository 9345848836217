import {useState} from "react";
import Forms from "../format/Forms";
import Libs from "../../Libs";
import axios from "axios";

const FormAccount = ({user, setUser}) => {

    const [firstName, setFirstName] = useState({text: user.firstName, check: true, error: ''});
    const [secondName, setSecondName] = useState({text: user.secondName, check: true, error: ''});
    const [email, setEmail] = useState({text: user.email, check: true, error: ''});
    const [password, setPassword] = useState({text: '', check: false, error: ''});
    const [passwordC, setPasswordC] = useState({text: '', check: false, error: ''});
    const [phone, setPhone] = useState({text: user.phone, check: true, error: ''});
    const [message, setMessage] = useState({msg1: '', msg2: ''});
    const [edit, setEdit] = useState(false);

    let firstNameField, secondNameField, emailField, phoneField, button;


    // Fonction qui actualise le state quand on modifie la valeur des input
    const changeField = (e, submit) => {

        let result;
        let value;

        // Action en fonction du champ modifié
        switch (e.target.name) {
            case 'firstName':
                value = submit ? firstName.text : e.target.value
                result = Libs.checkField('name', value);

                setFirstName({
                    text: value.charAt(0).toUpperCase() + value.slice(1),
                    check: result.check,
                    error: result.error})
                break;

            case 'secondName':
                value = submit ? secondName.text : e.target.value
                result = Libs.checkField('name', value);

                setSecondName({
                    text: value.toUpperCase(),
                    check: result.check,
                    error: result.error})
                break;

            case 'email':
                value = submit ? email.text : e.target.value
                result = Libs.checkField('email', value);

                setEmail({
                    text: value,
                    check: result.check,
                    error: result.error})
                break;

            case 'password':
                value = submit ? password.text : e.target.value
                result = Libs.checkField('password', value);

                setPassword({
                    text: value,
                    check: result.check,
                    error: result.error})
                break;

            case 'passwordC':
                value = submit ? passwordC.text : e.target.value
                result = Libs.checkField('passwordC', [value, password.text]);

                setPasswordC({
                    text: value,
                    check: result.check,
                    error: result.error})
                break;

            case 'phone':
                value = submit ? phone.text : e.target.value
                result = Libs.checkField('phone', value === null ? '' : value);

                setPhone({
                    text: value,
                    check: result.check,
                    error: result.error})
                break;

            default:
                break;
        }
    }


    // Fonction qui s'exécute quand on clique sur le bouton valider
    const sumbitProfil = (e) => {
        e.preventDefault();

        // On force la vérification de tous les champs
        changeField({target: {name: 'firstName'}}, true);
        changeField({target: {name: 'secondName'}}, true);
        changeField({target: {name: 'email'}}, true);
        changeField({target: {name: 'phone'}}, true);

        // Si tous les champs sont bon
        if(firstName.check && secondName.check && email.check && phone.check) {
            /* Requette vers le serveur backend
                .then si la requette vers le backend fonctionne bien
                .catch si une erreur de communication avec le backend
            */
            axios.post('/back/profile/update', {
                firstName: firstName,
                secondName: secondName,
                email: email,
                phone: phone,
                id: user.id
            })
                .then(function (response) {

                    // On vérifie si le backend nous retourne un message d'erreur ou non
                    if(response.data.status) {
                        setMessage({msg1: Libs.textValide(response.data.message1), msg2: Libs.textValide(response.data.message2)});
                        setEdit(false);
                        if(user.id === response.data.user_id) {
                            setUser({
                                id: user.id,
                                firstName: firstName.text,
                                secondName: secondName.text,
                                email: email.text,
                                phone: phone.text === '' ? null : phone.text,
                                grade: user.grade
                            });
                        }
                    }
                    else {
                        if(response.data.connected) {
                            setMessage({msg1: Libs.textError(response.data.message1), msg2: ''});
                        }
                        else {
                            window.location.reload();
                        }
                    }
                })
                .catch(function (error) {
                    axios.post('/back/error', {
                        code: 2000,
                        value: error
                    }).then(function (response) {});
                });
        }
    }


    // Fonction qui annule le mode edition
    const cancel = () => {
        setFirstName({text: user.firstName, check: true, error: ''});
        setSecondName({text: user.secondName, check: true, error: ''});
        setEmail({text: user.email, check: true, error: ''});
        setPhone({text: user.phone, check: true, error: ''});
        setMessage({msg1: '', msg2: ''});
        setEdit(false);
    }

    if(edit) {
        firstNameField = (<Forms type='text' value={firstName.text} name="firstName" func={(e) => changeField(e, false)} />);
        secondNameField = (<Forms type='text' value={secondName.text} name="secondName" func={(e) => changeField(e, false)} />);
        emailField = (<Forms type='email' value={email.text} name="email" func={(e) => changeField(e, false)} />);
        phoneField = (<Forms type='tel' value={phone.text === null ? "" : phone.text} name="phone" func={(e) => changeField(e, false)} />);
        button = (<div className="center"><button onClick={sumbitProfil}>Valider</button> <a href="/" onClick={(e) => {e.preventDefault(); cancel()}}>Annuler</a></div>);
    }
    else {
        firstNameField = (<>{firstName.text}<br/></>);
        secondNameField = (<>{secondName.text}<br/></>);
        emailField = (<>{email.text}<br/></>);
        phoneField = (<>{phone.text}<br/></>);
        button = (<div className="center"><button onClick={() => {setEdit(true); setMessage({msg1: '', msg2: ''})}}>Editer</button></div>);
    }


    return (
        <form onSubmit={(e) => {e.preventDefault();}}>
            <div className="center">
                {message.msg1}
                {message.msg2}
            </div>
            <br/>
            <h3>Prénom:</h3>{firstName.error} {firstNameField}
            <h3>Nom:</h3>{secondName.error} {secondNameField}
            <h3>Mail:</h3>{email.error} {emailField}
            <h3>Téléphone:</h3>{phone.error} {phoneField}
            {button}
        </form>
    )
}

export default FormAccount;