import {useParams, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import Libs from "../Libs";
import ErrorPage from "./ErrorPage";
import Title from "../components/format/Title";

const TrackingDelete = () => {

    const [acces, setAccess] = useState(false);
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState('');
    const [tracking, setTracking] = useState([{trackings_id: null, trackings_name: null}]);
    const {id} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        // On va récupérer la liste des messages du tracking
        axios.post('/back/tracking/details', {
            tracking_id: id
        })
            .then(function (response) {

                // On vérifie si le backend nous retourne un message d'erreur ou non
                if(response.data.status) {
                    setAccess(true);
                    setTracking(response.data.tracking)
                }
                else {
                    if(response.data.connected) {
                        setMessage(Libs.textError(response.data.message));
                    }
                    else {
                        window.location.reload();
                    }
                }

                setShow(true);
            })
            .catch(function (error) {
                axios.post('/back/error', {
                    code: 2000,
                    value: error
                }).then(function (response) {});
            });
        // eslint-disable-next-line
    }, [])

    // Fonction qui s'exécute quand on clique sur le bouton valider
    const sumbitForm = (e) => {
        e.preventDefault();

        // On exécute la supression du tracking
        axios.post('/back/tracking/delete', {
            tracking_id: id
        })
            .then(function (response) {

                // On vérifie si le backend nous retourne un message d'erreur ou non
                if(response.data.status) {
                    navigate('../../');
                }
                else {
                    if(response.data.connected) {
                        setMessage(Libs.textError(response.data.message));
                    }
                    else {
                        window.location.reload();
                    }
                }
            })
            .catch(function (error) {
                axios.post('/back/error', {
                    code: 2000,
                    value: error
                }).then(function (response) {});
            });
    }

    if(show) {
        if (acces) {
            return (
                <div className='page'>
                    <Title>Attention !</Title>
                    {message}
                    <h2>Êtes-vous sûr de vouloir supprimer le tracking: {tracking[0].trackings_name} ?</h2>
                    <br/>
                    <div>
                        <button className="deleteColor" onClick={sumbitForm}>Supprimer</button> <a href="/" onClick={(e) => {e.preventDefault(); navigate(-1)}}>Annuler</a>
                    </div>
                </div>
            );
        } else {
            return <ErrorPage/>;
        }
    }
}

export default TrackingDelete;