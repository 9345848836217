import {useNavigate, Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import ErrorPage from "../../pages/ErrorPage";
import Title from "../format/Title";

const FormRequestsDelete = (props) => {

    const [acces, setAccess] = useState(false);
    const [show, setShow] = useState(false);
    const [request, setRequest] = useState();
    const {id} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        axios.post('/back/requets/get', {
            request_id: id
        })
            .then(function (response) {
                // On vérifie si le backend nous retourne un message d'erreur ou non
                if(response.data.status) {
                    setRequest(response.data.values[0]);
                    setAccess(true);
                }
                else {
                    if(!response.data.connected) {
                        window.location.reload();
                    }
                }

                setShow(true);
            })
            .catch(function (error) {
                axios.post('/back/error', {
                    code: 2000,
                    value: error
                }).then(function (response) {});
            });
        // eslint-disable-next-line
    }, [props.groups_id])

    // Fonction qui s'exécute quand on clique sur le bouton valider
    const sumbitForm = (e) => {
        e.preventDefault();

        // On exécute la supression du tracking
        axios.post('/back/requets/delete', {
            request_id: request.add_request_id
        })
            .then(function (response) {

                // On vérifie si le backend nous retourne un message d'erreur ou non
                if(response.data.status) {
                    navigate('/admin/requests');
                }
                else {
                    if(!response.data.connected) {
                        window.location.reload();
                    }
                }
            })
            .catch(function (error) {
                axios.post('/back/error', {
                    code: 2000,
                    value: error
                }).then(function (response) {});
            });
    }

    if(show) {
        if (acces) {
            return (
                <div className="page">
                    <Title>Attention !</Title>
                    <div className="center">
                        <h2>Êtes-vous sûr de vouloir supprimer la demande: {request.add_request_url} ?</h2>
                        <br/>
                        <button className="deleteColor" onClick={sumbitForm}>Supprimer</button> <Link to={'/admin/requests'} >Annuler</Link>
                    </div>
                </div>
            );
        } else {
            return <ErrorPage/>;
        }
    }
}

export default FormRequestsDelete;