import {memo, useEffect, useState} from "react";
import Title from "../../components/format/Title";
import Forms from "../../components/format/Forms";
import axios from "axios";
import Libs from "../../Libs";
import UserList from "../../components/blocs/UserList";
import FormAccount from "../../components/forms/FormAccount";
import {useNavigate, useParams} from "react-router-dom";

const AdminUsersEdit = () => {

    const [message, setMessage] = useState('');
    const [users, setUsers] = useState([]);
    const [show, setShow] = useState(false);
    const {email} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        axios.post('/back/admin/user/get', {
            search: email
        })
            .then(function (response) {
                // On vérifie si le backend nous retourne un message d'erreur ou non
                if(response.data.status) {
                    setMessage(Libs.textValide(response.data.message));
                    setUsers(response.data.values);
                    setShow(true);
                }
                else {
                    if(response.data.connected) {
                        setMessage(Libs.textError(response.data.message));
                    }
                    else {
                        window.location.reload();
                    }
                }
            })
            .catch(function (error) {
                axios.post('/back/error', {
                    code: 2000,
                    value: error
                }).then(function (response) {});
            });
    }, []);

    if(show) {
        return (
            <div className='page'>
                <Title>Editer Utilisateur</Title>
                {message}
                <FormAccount user={{
                    'firstName': users[0].users_first_name,
                    'secondName': users[0].users_second_name,
                    'email': users[0].users_mail,
                    'phone': users[0].users_number_phone,
                    'id': users[0].users_id
                }} />
                <a href="/" onClick={(e) => {e.preventDefault(); navigate(-1)}}>Retour</a>
            </div>
        );
    }
}

export default memo(AdminUsersEdit)