import Title from "../components/format/Title";

const ErrorPage = () => {
    return (
        <div className='page'>
            <Title>Erreur 404</Title>
        </div>
    )
}

export default ErrorPage;