import {memo, useState} from "react";
import Title from "../components/format/Title";
import FormGroupeAdd from "../components/forms/FormGroupAdd";
import GroupList from "../components/blocs/GroupList";
import {useParams} from "react-router-dom";
import ErrorPage from "./ErrorPage";
import FormGroupEdit from "../components/forms/FormGroupEdit";
import FormGroupDelete from "../components/forms/FormGroupDelete";

const GroupView = () => {
    const [message, setMessage] = useState('');
    const [update, setUpdate] = useState(false);
    const {action, id} = useParams();

    let showAction;

    if(action === undefined) {
        showAction = <FormGroupeAdd infos={setMessage} update={update} setUpdate={setUpdate} />;
    }
    else {
        switch (action) {
            case 'edit':
                showAction = <FormGroupEdit infos={setMessage} update={update} setUpdate={setUpdate} groups_id={id} />;
                break;
            case 'delete':
                showAction = <FormGroupDelete infos={setMessage} update={update} setUpdate={setUpdate} groups_id={id} />
                break;
            default:
                showAction = <ErrorPage />;
                break;
        }
    }

    return (
        <div className='page'>
            <Title>Groupes</Title>
            {message}
            {showAction}
            <br/>
            <br/>
            <GroupList update={update}/>
        </div>
    );
}

export default memo(GroupView)