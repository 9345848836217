import Forms from "../format/Forms";
import {useState} from "react";
import axios from "axios";
import Libs from "../../Libs";

const FormAddRequest = () => {

    const [reference, setReference] = useState({text: '', check: false, error: ''});
    const [url, setUrl] = useState({text: '', check: false, error: ''});
    const [message, setMessage] = useState('');


    // Fonction qui actualise le state quand on modifie la valeur des input
    const changeField = (e, submit) => {

        let result;
        let value;

        // Action en fonction du champ modifié
        switch (e.target.name) {
            case 'reference':
                value = submit ? reference.text : e.target.value
                result = Libs.checkField('nbCharac', [1, 255, value]);

                setReference({
                    text: value,
                    check: result.check,
                    error: result.error})
                break;

            case 'url':
                value = submit ? url.text : e.target.value
                result = Libs.checkField('nbCharac', [12, 255, value]);

                setUrl({
                    text: value,
                    check: result.check,
                    error: result.error})
                break;

            default:
                break;
        }
    }


    // Fonction qui s'exécute quand on clique sur le bouton envoyer
    const sumbitForm = (e) => {
        e.preventDefault();

        changeField({target: {name: 'reference'}}, true);
        changeField({target: {name: 'url'}}, true);

        // On vérifie que tous les champs sont bon
        if(reference.check && url.check) {
            axios.post('/back/requets/add', {
                reference: reference.text,
                url: url.text
            })
                .then(function (response) {
                    // On vérifie si le backend nous retourne un message d'erreur ou non
                    if(response.data.status) {
                        setMessage(Libs.textValide(response.data.message));
                        setReference({text: '', check: false, error: ''});
                        setUrl({text: '', check: false, error: ''});
                    }
                    else {
                        if(response.data.connected) {
                            setMessage(Libs.textError(response.data.message));
                        }
                        else {
                            window.location.reload();
                        }
                    }
                })
                .catch(function (error) {
                    axios.post('/back/error', {
                        code: 2000,
                        value: error
                    }).then(function (response) {});
                });
        }
    }

    return (
        <div className="pageContent">
            <div className="center">{message}</div>
            <form className='form' onSubmit={sumbitForm}>
                <Forms type='text' value={reference.text} name="reference" func={(e) => changeField(e, false)}>Référence Colis (pour tests)</Forms>
                {reference.error}
                <br/>
                <Forms type='url' value={url.text} name="url" func={(e) => changeField(e, false)}>URL Site Livreur</Forms>
                {url.error}
                <br/>
                <div className="center">
                    <Forms type='submit'>Envoyer</Forms>
                </div>
            </form>
        </div>
    );
}

export default FormAddRequest;