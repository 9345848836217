import Title from "../components/format/Title";
import './pages.css';

const CGU = () => {
    return (
        <div className='page'>
            <Title>Conditions générales d'utilisation du site "Doudou Tracking"</Title>
            <div>
                <h2>ARTICLE 1 : Objet</h2>
                <p>Les présentes « conditions générales d'utilisation » ont pour objet l'encadrement juridique de l’utilisation du site "Doudou Tracking" et de ses services.</p>
                <p>
                    Ce contrat est conclu entre :<br />
                    Le gérant du site internet, ci-après désigné « l’Éditeur »,<br />
                    Toute personne physique ou morale souhaitant accéder au site et à ses services, ci-après appelé « l’Utilisateur ».<br />
                    Les conditions générales d'utilisation doivent être acceptées par tout Utilisateur, et son accès au site vaut acceptation de ces conditions.
                </p>
                <br />
                <h2>ARTICLE 2 : Mentions légales</h2>
                <p>
                    Pour les personnes physiques :<br />
                        Le site "Doudou Tracking" est édité par CHECCONI Dorian, domicilié au 8 Petit chemin de Vauranne, 13800 Istres.
                </p>
                <br />
                <h2>ARTICLE 3 : Accès aux services</h2>
                <p>
                    L’Utilisateur du site "Doudou Tracking" a accès aux services suivants :<br />
                    •	Ajout/Consultation/Supression de suivis de colis<br />
                    •	Activation/Désactivation des notifications par MAIL ou SMS<br />
                    •	Gestion de ses données personnelles<br />
                    •	Visualisation des informations<br />
                    •	Modification et suppression de toutes informations
                </p>
                <p>
                    Tout Utilisateur ayant accès à internet peut accéder gratuitement et depuis n’importe où au site. Les frais supportés par l’Utilisateur pour y accéder (connexion internet, matériel informatique, etc.) ne sont pas à la charge de l’Éditeur.<br />
                    Le site et ses différents services peuvent être interrompus ou suspendus par l’Éditeur, notamment à l’occasion d’une maintenance, sans obligation de préavis ou de justification.
                </p>
                <br />
                <h2>ARTICLE 4 : Responsabilité de l’Utilisateur</h2>
                <p>
                    L'Utilisateur est responsable des risques liés à l’utilisation de son identifiant de connexion et de son mot de passe.<br />
                    Le mot de passe de l’Utilisateur doit rester secret. En cas de divulgation de mot de passe, l’Éditeur décline toute responsabilité.<br />
                    L’Utilisateur assume l’entière responsabilité de l’utilisation qu’il fait des informations et contenus présents sur le site "Doudou Tracking".<br />
                    Tout usage du service par l'Utilisateur ayant directement ou indirectement pour conséquence des dommages doit faire l'objet d'une indemnisation au profit du site.
                </p>
                <br />
                <h2>ARTICLE 5 : Responsabilité de l’Éditeur</h2>
                <p>
                    Tout dysfonctionnement du serveur ou du réseau ne peut engager la responsabilité de l’Éditeur.<br />
                    De même, la responsabilité du site ne peut être engagée en cas de force majeure ou du fait imprévisible et insurmontable d'un tiers.<br />
                    Le site "Doudou Tracking" s'engage à mettre en œuvre tous les moyens nécessaires pour garantir la sécurité et la confidentialité des données. Toutefois, il n’apporte pas une garantie de sécurité totale.
                </p>
                <br />
                <h2>ARTICLE 6 : Propriété intellectuelle</h2>
                <p>
                    Les contenus du site "Doudou Tracking" (logos, textes, éléments graphiques, vidéos, etc.) sont protégés par le droit d’auteur, en vertu du Code de la propriété intellectuelle.<br />
                    L’Utilisateur devra obtenir l’autorisation de l’éditeur du site avant toute reproduction, copie ou publication de ces différents contenus.<br />
                    Ces derniers peuvent être utilisés par les utilisateurs à des fins privées ; tout usage commercial est interdit.<br />
                    L’Utilisateur est entièrement responsable de tout contenu qu’il met en ligne et il s’engage à ne pas porter atteinte à un tiers.
                </p>
                <br />
                <h2>ARTICLE 7 : Données personnelles</h2>
                <p>
                    L’Utilisateur doit obligatoirement fournir des informations personnelles pour procéder à son inscription sur le site.<br />
                    L’adresse électronique (e-mail) de l’utilisateur pourra notamment être utilisée par le site "Doudou Tracking" pour la communication d’informations diverses et la gestion du compte.<br />
                    "Doudou Tracking" garantie le respect de la vie privée de l’utilisateur, conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés.
                </p>
                <p>
                    En vertu des articles 39 et 40 de la loi en date du 6 janvier 1978, l'Utilisateur dispose d'un droit d'accès, de rectification, de suppression et d'opposition de ses données personnelles. L'Utilisateur exerce ce droit via :<br />
                    •	Son espace personnel sur le site.<br />
                    •	Un formulaire de contact.<br />
                    •	Par mail à doudou-tracking@dorianchecconi.fr
                </p>
                <br />
                <h2>ARTICLE 8 : Évolution des conditions générales d’utilisation</h2>
                <p>Le site "Doudou Tracking" se réserve le droit de modifier les clauses de ces conditions générales d’utilisation à tout moment et sans justification.</p>
                <br />
                <h2>ARTICLE 9 : Durée du contrat</h2>
                <p>La durée du présent contrat est indéterminée. Le contrat produit ses effets à l'égard de l'Utilisateur à compter du début de l’utilisation du service.</p>
                <br />
                <h2>ARTICLE 10 : Droit applicable et juridiction compétente</h2>
                <p>
                    Le présent contrat dépend de la législation française.<br />
                    En cas de litige non résolu à l’amiable entre l’Utilisateur et l’Éditeur, les tribunaux de [nom de ville] sont compétents pour régler le contentieux.
                </p>
                <br />
                <h2>ARTICLE 11 : Sécurité des informations</h2>
                <p>
                    Toutes les informations inscrites par l’utilisateur sur le site "Doudou Tracking" sont enregistrées dans une base sécurisée.<br />
                    Les présentes informations sont entièrement chiffrées:<br />
                    •	Mot de Passe<br />
                    <br />
                        Qui ne sont par conséquent non lisible par l’éditeur du site "Doudou Tracking".
                </p>
                <br />
                <h2>Crédits</h2>
                Fond d'écran: <a href="https://fr.lovepik.com/images/backgrounds-922939.html">Business Png vectors by Lovepik.com</a>
                Icons:
                <a href="https://www.flaticon.com/fr/icones-gratuites/envoi-en-cours" title="envoi en cours icônes">Envoi en cours icônes créées par Andrean Prabowo - Flaticon</a>
                <a href="https://www.flaticon.com/fr/icones-gratuites/mise-en-garde" title="mise en garde icônes">Mise en garde icônes créées par Good Ware - Flaticon</a>
                <a href="https://www.flaticon.com/free-icons/delete" title="delete icons">Delete icons created by Pixel perfect - Flaticon</a>
                <a href="https://www.flaticon.com/fr/icones-gratuites/livraison-de-colis" title="livraison de colis icônes">Livraison de colis icônes créées par denimao - Flaticon</a>
                <a href="https://www.flaticon.com/fr/icones-gratuites/correct" title="correct icônes">Correct icônes créées par Pixel perfect - Flaticon</a>
                <a href="https://www.flaticon.com/fr/icones-gratuites/nouveau" title="nouveau icônes">Nouveau icônes créées par Pixel Buddha - Flaticon</a>
                <a href="https://www.flaticon.com/fr/icones-gratuites/doute" title="doute icônes">Doute icônes créées par Freepik - Flaticon</a>
                <a href="https://www.flaticon.com/authors/uniconlabs" title="Uniconlabs"> Uniconlabs - Flaticon</a>
                <a href="https://www.flaticon.com/free-icons/edit" title="edit icons">Edit icons created by Freepik - Flaticon</a>
                <a href="https://www.flaticon.com/fr/auteurs/sumberrejeki" title="SumberRejeki"> SumberRejeki </a>
            </div>
        </div>
    )
}

export default CGU;