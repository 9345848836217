import {useEffect, useState} from "react";
import axios from "axios";
import icon_edit from "../../assets/icons/edit.png";
import {Link} from "react-router-dom";
import icon_delete from "../../assets/icons/delete.png";

const GroupView = (props) => {

    const [groups, setGroups] = useState([]);

    let list;

    useEffect(() => {
        axios.post('/back/groups/get')
            .then(function (response) {
                // On vérifie si le backend nous retourne un message d'erreur ou non
                if(response.data.status) {
                    setGroups(response.data.values);
                }
                else {
                    if(!response.data.connected) {
                        window.location.reload();
                    }
                }
            })
            .catch(function (error) {
                axios.post('/back/error', {
                    code: 2000,
                    value: error
                }).then(function (response) {});
            });
    }, [props.update])

    let color = false;

    if(groups.length > 0) {
        list = (
            <table>
                <tbody>
                {
                    groups.map((value, index) => {
                        color = color ? false : true;
                        return (
                            <tr key={index} className={color ? 'backTr' : ''}>
                                <td className="tdGroups">
                                    {value.groups_name}
                                </td>
                                <td className="leftBar tdGroups tdMin">
                                    <Link to={`/groups/edit/${value.groups_id}`} ><img className="icon" alt="edit" src={icon_edit}/></Link> <Link to={`/groups/delete/${value.groups_id}`} ><img className="icon" alt='delete' src={icon_delete}/></Link>
                                </td>
                            </tr>
                        );
                    })
                }
                </tbody>
            </table>
        );
    }
    else {
        list = "Vous n'avez aucun groupe";
    }

    return (
        <div className="center">
            <h2>Liste des groupes</h2>
            <br/>
            {list}
        </div>
    );
}

export default GroupView