import {memo, useEffect, useState} from "react";
import Title from "../../components/format/Title";
import Forms from "../../components/format/Forms";
import axios from "axios";
import Libs from "../../Libs";
import UserList from "../../components/blocs/UserList";

const AdminUsers = () => {

    const [message, setMessage] = useState('');
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
        axios.post('/back/admin/user/get', {
            search: search
        })
            .then(function (response) {
                // On vérifie si le backend nous retourne un message d'erreur ou non
                if(response.data.status) {
                    setMessage(Libs.textValide(response.data.message));
                    setUsers(response.data.values);
                }
                else {
                    if(response.data.connected) {
                        setMessage(Libs.textError(response.data.message));
                    }
                    else {
                        window.location.reload();
                    }
                }
            })
            .catch(function (error) {
                axios.post('/back/error', {
                    code: 2000,
                    value: error
                }).then(function (response) {});
            });
    }, [search]);

    return (
        <div className='page'>
            <Title>Administration Utilisateurs</Title>
            {message}
            <form className='form' onSubmit={(e) => {e.preventDefault()}}>
                <Forms type='search' value={search} name="search" func={(e) => {setSearch(e.target.value)}}>Rechercher</Forms>
            </form>
            <br/>
            <br/>
            <UserList values={users}/>
        </div>
    );
}

export default memo(AdminUsers)