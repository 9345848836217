import Forms from "../format/Forms";
import Libs from "../../Libs";
import {useState} from "react";
import axios from "axios";
import Title from "../format/Title";
import {Link} from "react-router-dom";

const FormResetPassword = ({id, key}) => {

    const [email, setEmail] = useState({text: '', check: false, error: ''});
    const [message, setMessage] = useState('');

    // Fonction qui actualise le state quand on modifie la valeur des input
    const changeField = (e, submit) => {

        let result;
        let value;

        value = submit ? email.text : e.target.value
        result = Libs.checkField('email', value);

        setEmail({
            text: value,
            check: result.check,
            error: result.error})
    }

    const submitForm = (e) => {
        e.preventDefault();

        // On force la vérification de tous les champs
        changeField({target: {name: 'email'}}, true);

        // Si tous les champs sont bon
        if(email.check) {
            /* Requette vers le serveur backend
                .then si la requette vers le backend fonctionne bien
                .catch si une erreur de communication avec le backend
            */
            axios.post('/back/resetPassword', {
                email: email
            })
                .then(function (response) {
                    if(response.data.status) {
                        setMessage(Libs.textValide('Un mail a été envoyé à l\'adresse mail indiqué si un compte existe'));
                        setEmail({text: '', check: false, error: ''});
                    }
                    else {
                        setMessage(Libs.textError(response.data.message));
                    }
                })
                .catch(function (error) {
                    axios.post('/back/error', {
                        code: 2000,
                        value: error
                    }).then(function (response) {});
                });
        }
    }

    return (
        <form onSubmit={submitForm}>
            <Title>Réinitialiser Mot de Passe</Title>
            <div className="center">
                {message}
            </div>
            <br/>
            <Forms type='email' value={email.text} name="email" func={(e) => changeField(e, false)} placeholder="robert.petit@mail.fr">Mail</Forms>
            {email.error}
            <br/>
            <div className="center"><Forms type='submit'>Envoyer</Forms> <Link className="linkLoRI" to={'/login'} >Annuler</Link></div>
        </form>
    );
}

export default FormResetPassword;