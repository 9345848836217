import Libs from "../../Libs";
import {useEffect, useState} from "react";
import axios from "axios";

const LoginHistory = () => {

    const [history, setHistory] = useState([]);
    const [message, setMessage] = useState('');

    useEffect(() => {
        // On récupère l'historique de connexion
        axios.post('/back/login-history/get')
            .then(function (response) {
                // On vérifie si le backend nous retourne un message d'erreur ou non
                if(response.data.status) {
                    setHistory(response.data.values);
                }
                else {
                    if(response.data.connected) {
                        setMessage(Libs.textError('Une erreur est survenue'));
                    }
                    else {
                        window.location.reload();
                    }
                }
            })
            .catch(function (error) {
                axios.post('/back/error', {
                    code: 2000,
                    value: error
                }).then(function (response) {});
            });
    }, []);

    const deleteHistory = () => {
        // On supprime l'historique
        axios.post('/back/login-history/delete')
            .then(function (response) {
                // On vérifie si le backend nous retourne un message d'erreur ou non
                if(response.data.status) {
                    setHistory([]);
                    setMessage(Libs.textValide(response.data.message));
                }
                else {
                    if(response.data.connected) {
                        setMessage(Libs.textError('Une erreur est survenue'));
                    }
                    else {
                        window.location.reload();
                    }
                }
            })
            .catch(function (error) {
                axios.post('/back/error', {
                    code: 2000,
                    value: error
                }).then(function (response) {});
            });
    }

    let ligne = false;

    return (
        <div className="center">
            {message}
            <br/>
            <h3>Historique de connexions</h3>
            <table>
                <thead>
                    <tr>
                        <th>
                            Date
                        </th>
                        <th>
                            IP
                        </th>
                        <th>
                            Navigateur
                        </th>
                    </tr>
                </thead>
                <tbody>
                {
                    history.map((value, index) => {
                        ligne = ligne ? false : true;
                        return (
                            <tr key={index} className={ligne ? 'backTr' : ''}>
                                <td>
                                    {Libs.showDate(value.connections_log_date)}
                                </td>
                                <td className="leftBar">
                                    {value.connections_log_ip}
                                </td>
                                <td className="leftBar">
                                    {value.connections_log_agent}
                                </td>
                            </tr>
                        );
                    })
                }
                </tbody>
            </table>
            {history.length > 0 ? (<button className="deleteColor" onClick={deleteHistory}>Effacer l'historique</button>) : ''}
        </div>
    );
}

export default LoginHistory;