import icon_edit from "../../assets/icons/edit.png";
import {Link} from "react-router-dom";
import icon_delete from "../../assets/icons/delete.png";
import Libs from "../../Libs";

const UserList = (props) => {

    let list;
    let color = false;

    if(props.values.length > 0) {
        list = (
            <table>
                <tbody>
                {
                    props.values.map((value, index) => {
                        color = color ? false : true;

                        let status;

                        if(value.users_actif){
                            status = Libs.textValide('Actif');
                        }
                        else {
                            status = Libs.textError('Inactif');
                        }

                        return (
                            <tr key={index} className={color ? 'backTr' : ''}>
                                <td className="tdGroups">
                                    {value.users_first_name}
                                </td>
                                <td className="tdGroups">
                                    {value.users_second_name}
                                </td>
                                <td className="tdGroups">
                                    {value.users_number_phone}
                                </td>
                                <td className="tdGroups">
                                    {value.users_mail}
                                </td>
                                <td className="tdGroups">
                                    {status}
                                </td>
                                <td className="leftBar tdGroups tdMin">
                                    <Link to={`/admin/users/edit/${encodeURI(value.users_mail)}`} ><img className="icon" alt="edit" src={icon_edit}/></Link> <Link to={`/admin/users/delete/${encodeURI(value.users_mail)}`} ><img className="icon" alt='delete' src={icon_delete}/></Link>
                                </td>
                            </tr>
                        );
                    })
                }
                </tbody>
            </table>
        );
    }
    else {
        list = "Aucun utilisateur trouvé";
    }

    return (
        <div className="center">
            <h2>Liste des utilisateurs</h2>
            <br/>
            {list}
        </div>
    );
}

export default UserList