import {memo} from 'react';

const Forms = ({type, children, value, name, func, obligatory, placeholder, selected, checked}) => {

    const stars = obligatory ? <span className="obligatory">*</span> : '';


    switch (type) {
        case 'select':
            return (
                <select onChange={func}>
                    {value.map((name, index) => {
                        return <option key={index} value={name}>{name}</option>
                    })}
                </select>
            );

        case 'selectCompanies':
            return (
                <label>Livreur{stars}
                    <br/>
                    <select onChange={func} id={name} name={name}>
                        <option key="0" value="0"></option>
                        {value.map((data) => {
                            return <option key={data.companies_id} value={data.companies_id}>{data.companies_name}</option>
                        })}
                    </select>
                    <br/>
                </label>
            );

        case 'selectGroups':
            return (
                <label>Groupe{stars}
                    <br/>
                    <select onChange={func} id={name} name={name} value={selected}>
                        <option key="0" value="0">{children}</option>
                        {value.map((data) => {
                            return <option key={data.groups_id} value={data.groups_id}>{data.groups_name}</option>
                        })}
                    </select>
                    <br/>
                </label>
            );

        case 'selectListNumber':
            return (
                <label>{children}
                    <select onChange={func} id={name} name={name} value={selected}>
                        {value.map((data) => {
                            return <option key={data} value={data}>{data}</option>
                        })}
                    </select>
                    <br/>
                </label>
            );

        case 'selectListName':
            let increment = 0;
            return (
                <label>{children}
                    <select onChange={func} id={name} name={name} value={selected}>
                        {value.map((data) => {
                            increment = increment+1;
                            return <option key={increment} value={increment}>{data}</option>
                        })}
                    </select>
                    <br/>
                </label>
            );

        case 'submit':
            return <button type="submit">{children}</button>;

        case 'checkbox':
            return (
                <>
                    <br/>
                    <label><input className="checkbox" type="checkbox" id={name} name={name} value={value} onChange={func} checked={checked} />{children}</label>
                </>

            );

        case 'textarea':
            return (
                <>
                    <label>{children}{stars}<br/><textarea rows="5" className="field" type={type} id={name} name={name} value={value} onChange={func} placeholder={placeholder} /></label>
                    <br/>
                </>
            );

        default:
            return (
                <>
                    <label>{children}{stars}<br/><input className="field" type={type} id={name} name={name} value={value} onChange={func} placeholder={placeholder} /></label>
                    <br/>
                </>
            );
    }
}

export default memo(Forms);