import Title from "../components/format/Title";
import './pages.css';
import FormTrackingAdd from "../components/forms/FormTrackingAdd";

const TrackingAdd = () => {
    return (
        <div className='page'>
            <Title>Nouveau Tracking</Title>
            <FormTrackingAdd />
        </div>
    )
}

export default TrackingAdd;