import Title from "../components/format/Title";
import './pages.css';
import './tracking.css';
import TrackingBloc from "../components/blocs/TrackingBloc";
import {Link} from "react-router-dom";
import {memo, useEffect, useState} from "react";
import axios from "axios";
import Libs from '../Libs';
import Forms from "../components/format/Forms";

const TrackingView = (props) => {

    const [trackings, setTrackings] = useState([]);
    const [message, setMessage] = useState('');
    const [groups, setGroups] = useState({values: [], choice: props.data.group, check: false, error: ''});

    useEffect(() => {
        // On récupère la liste des groupes
        axios.post('/back/groups/get')
            .then(function (response) {
                // On vérifie si le backend nous retourne un message d'erreur ou non
                if(response.data.status) {
                    setMessage(Libs.textValide(response.data.message));
                    setGroups({values: response.data.values, choice: props.data.group, check: false, error: ''})
                }
                else {
                    if(response.data.connected) {
                        setMessage(Libs.textError('Une erreur est survenue'));
                    }
                    else {
                        window.location.reload();
                    }
                }
            })
            .catch(function (error) {
                axios.post('/back/error', {
                    code: 2000,
                    value: error
                }).then(function (response) {});
            });
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        // On va récupérer la liste des trackings de l'utilisateur
        axios.post('/back/tracking/get', {
            group: groups.choice
        })
            .then(function (response) {

                // On vérifie si le backend nous retourne un message d'erreur ou non
                if(response.data.status) {
                    setTrackings(response.data.trackings)

                    if(groups.choice !== props.data.group && groups.choice !== 0) {
                        props.edit({group: groups.choice});
                    }
                }
                else {
                    if(response.data.connected) {
                        setMessage(Libs.textError(response.data.message));
                    }
                    else {
                        window.location.reload();
                    }
                }
            })
            .catch(function (error) {
                axios.post('/back/error', {
                    code: 2000,
                    value: error
                }).then(function (response) {});
            });
        // eslint-disable-next-line
    }, [groups])


    // Fonction qui actualise le state quand on modifie la valeur des input
    const changeGroup = (e) => {
        setGroups({
            values: groups.values,
            choice: e.target.value,
            check: groups.check,
            error: groups.error})
    }

    return (
        <div className='page'>
            <Title>Tracking</Title>
            {message}
            <Link to={"add"}><button>Nouveau Tracking</button></Link>
            <div className="selectGroup">
                <Forms type='selectGroups' value={groups.values} name="groups" func={changeGroup} selected={props.data.group} >Tous</Forms>
            </div>
            <div className='trackingView'>
                {
                    trackings.map((value, index) => {
                        return <TrackingBloc key={index} data={value} />
                    })
                }
            </div>
        </div>
    )
}

export default memo(TrackingView);