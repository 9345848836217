import Forms from "../format/Forms";
import {memo, useEffect, useState} from "react";
import axios from "axios";
import Libs from "../../Libs";

const FormNotifications = ({user}) => {

    const [boxNews, setBoxNews] = useState(false);
    const [boxTrackingMail, setBoxTrackingMail] = useState(false);
    const [boxTrackingSms, setBoxTrackingSms] = useState(false);
    const [change, setChange] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        // On récupère la liste des notifications
        axios.post('/back/notifications/get')
            .then(function (response) {
                // On vérifie si le backend nous retourne un message d'erreur ou non
                if(response.data.status) {
                    setBoxNews(response.data.values[0].notifications_news === 1);
                    setBoxTrackingMail(response.data.values[0].notifications_tracking_email === 1);
                    setBoxTrackingSms(response.data.values[0].notifications_tracking_sms === 1);
                }
                else {
                    if(response.data.connected) {
                        setMessage(Libs.textError('Une erreur est survenue'));
                    }
                    else {
                        window.location.reload();
                    }
                }
            })
            .catch(function (error) {
                axios.post('/back/error', {
                    code: 2000,
                    value: error
                }).then(function (response) {});
            });
    }, []);


    // Fonction de mise à jour en BDD
    useEffect(() => {
        if(change) {
            axios.post('/back/notifications/edit', {
                news: boxNews,
                track_mail: boxTrackingMail,
                track_sms: boxTrackingSms
            })
                .then(function (response) {
                    // On vérifie si le backend nous retourne un message d'erreur ou non
                    if(response.data.status) {
                        setMessage(Libs.textValide(response.data.message));
                    }
                    else {
                        if(response.data.connected) {
                            setMessage(Libs.textError('Une erreur est survenue'));
                        }
                        else {
                            window.location.reload();
                        }
                    }

                    setChange(false);
                })
                .catch(function (error) {
                    axios.post('/back/error', {
                        code: 2000,
                        value: error
                    }).then(function (response) {});
                });
        }
        // eslint-disable-next-line
    }, [change]);


    // Fonction qui actualise le state quand on modifie la valeur des input
    const changeField = (e, submit) => {

        setMessage('');

        // Action en fonction du champ modifié
        switch (e.target.name) {
            case 'boxNews':
                setBoxNews(e.target.checked);
                break;

            case 'boxTrackingMail':
                setBoxTrackingMail(e.target.checked);
                break;

            case 'boxTrackingSms':
                if(e.target.checked) {
                    if(user.phone !== null) {
                        setBoxTrackingSms(true);
                    }
                    else {
                        setMessage(Libs.textError('Veuillez ajouter un numéro dans "Mon Compte"'));
                    }
                }
                else {
                    setBoxTrackingSms(false);
                }
                break;

            default:
                break;
        }

        setChange(true);
    }

    return (
        <div className="center">
            {message}
            <form className="maxWidth">
                <Forms type="checkbox" value="boxNews" name="boxNews" checked={boxNews} func={(e) => changeField(e, false)}> News Doudou Tracking (mail)</Forms>
                <Forms type="checkbox" value="boxTrackingMail" name="boxTrackingMail" checked={boxTrackingMail} func={(e) => changeField(e, false)}> Notifications Tracking (mail)</Forms>
                <Forms type="checkbox" value="boxTrackingSms" name="boxTrackingSms" checked={boxTrackingSms} func={(e) => changeField(e, false)}> Notifications Tracking (sms)</Forms>
                <br/>
                <span className="infoPhone">{boxTrackingSms ? '(Vous recevrez les notifications depuis le: 07 66 60 44 54)' : ''}</span>
            </form>
        </div>
    );
}

export default memo(FormNotifications);